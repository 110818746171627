import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  CloseOutlined,
  UserOutlined,
  LockOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  ClockCircleOutlined
} from '@ant-design/icons'
import { Steps } from 'antd'
import { useAuth } from '../hooks/AuthContext'
import { FaGoogle, FaLine } from 'react-icons/fa'
import cityData from '../utils/cityData'
import { Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import api from '../utils/api'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    border-radius: 0;
    padding: 15px;
  }
`

const CloseButton = styled(CloseOutlined)`
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  cursor: pointer;
  color: #666;
`

const StyledSteps = styled(Steps)`
  margin: 20px 0 40px;

  // 根據是否可點擊設置游標樣式
  .ant-steps-item {
    cursor: default;

    &.can-click {
      cursor: pointer;

      &:hover {
        .ant-steps-item-title {
          color: #ff4d6d !important;
        }
      }
    }
  }

  // 禁用狀態的樣式
  .ant-steps-item-disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      .ant-steps-item-title {
        color: rgba(0, 0, 0, 0.45) !important;
      }
    }
  }

  // 確保在手機版時步驟保持水平
  &.ant-steps {
    @media (max-width: 768px) {
      .ant-steps-item-icon {
        margin-inline-end: 8px !important;
      }

      .ant-steps-item {
        padding-inline-start: 4px !important;
        padding-inline-end: 4px !important;
      }

      // 隱藏步驟描述文字，只保留標題
      .ant-steps-item-description {
        display: none;
      }

      // 調整標題文字大小
      .ant-steps-item-title {
        font-size: 14px;
        width: 100%;
        padding-inline-end: 0;
      }
      .ant-steps-item-tail::after {
        display: none !important;
      }

      // 保持水平佈局
      flex-direction: row !important;
    }

    // 更小螢幕的額外調整
    @media (max-width: 480px) {
      .ant-steps-item-title {
        font-size: 12px;
      }
    }
  }
`

const Section = styled.div`
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
`

const SectionTitle = styled.h3`
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  .required {
    color: #ff4d6d;
    margin-left: 4px;
  }
`

const DeliveryOption = styled.label`
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    border-color: #d3b1b7;
  }

  input[type='radio'] {
    margin-right: 10px;
  }

  .fee {
    margin-left: auto;
    color: #ff4d6d;
  }

  ${(props) =>
    props.checked &&
    `
    border-color: #d3b1b7;
    background-color: #fdf6f6;
  `}
`

const LoginSection = styled.div`
  text-align: center;
  padding: 20px;

  h3 {
    margin-bottom: 20px;
    color: #333;
  }
`

const LoginButton = styled.button`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #f5f5f5;
  }

  &.google {
    color: #e71306;
    font-size: 16px;
  }

  &.line {
    color: #3bb263;
    font-size: 16px;
  }

  &.platform {
    background: #f5f5f5;
    color: #d45d7a;
    border: none;
    font-size: 16px;
  }
  svg {
    font-size: 20px;
  }
`

const LoginForm = styled.form`
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;

  input {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
  }
`

const TotalAmount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;

  .amount {
    font-size: 24px;
    color: #ff4d6d;
    font-weight: bold;
  }
`

const NextButton = styled.button`
  width: 100%;
  padding: 15px;
  background: #e14a63;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background: #e14a63;
  }
`

const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
`

const FormSelect = styled.select`
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: white;

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  background: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 20px;

  .icon {
    color: #666;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .name {
    color: #333;
    font-weight: 500;
  }

  .member-tag {
    background: #ff4d6d;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    margin-left: auto;
  }
`

// 新增店家資訊樣式
const StoreInfo = styled.div`
  background: #f8f8f8;
  padding: 15px;
  border-radius: 8px;
  margin: 10px 0;

  h4 {
    margin: 0 0 10px 0;
    color: #333;
  }

  p {
    margin: 5px 0;
    color: #666;
  }

  .store-hours {
    color: #ff4d6d;
    font-size: 0.9em;
    margin-top: 10px;
  }
`

const CheckoutModal = ({
  isOpen,
  onClose,
  total,
  cartData,
  isDirectCheckout = false
}) => {
  const { user, login } = useAuth()
  const [currentStep, setCurrentStep] = useState(0)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [loginData, setLoginData] = useState({ username: '', password: '' })
  const [selectedDelivery, setSelectedDelivery] = useState('')
  const [selectedPayment, setSelectedPayment] = useState('')
  const [saveAddress, setSaveAddress] = useState(false)
  const [recipientData, setRecipientData] = useState({
    name: '',
    gender: '',
    phone: '',
    email: '',
    city: '',
    district: '',
    address: '',
    deliveryTime: '',
    note: ''
  })
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [paymentError, setPaymentError] = useState(null)
  const [paymentStep, setPaymentStep] = useState('form') // form | processing | success | fail | error
  const navigate = useNavigate()
  const [orderData, setOrderData] = useState(null)
  const [paymentResult, setPaymentResult] = useState(null)

  // 從 cityData 獲取城市列表
  const getCities = () => {
    return cityData.districts.map((district) => ({
      value: district.name,
      label: district.name
    }))
  }

  // 根據選擇的城市獲取區域列表
  const getDistricts = (cityName) => {
    const selectedCity = cityData.districts.find(
      (district) => district.name === cityName
    )
    if (!selectedCity) return []

    return selectedCity.areas.map((area, index) => ({
      value: area,
      label: area,
      zipCode: selectedCity.zipCodes[index]
    }))
  }

  // 當城市改變時重置區域
  const handleCityChange = (e) => {
    const newCity = e.target.value
    setRecipientData({
      ...recipientData,
      city: newCity,
      district: '' // 重區域
    })
  }

  // 當用戶登入後自動填入資料
  useEffect(() => {
    if (user) {
      setRecipientData((prev) => ({
        ...prev,
        name: user.name || '', // 用戶名稱
        phone: user.phone || '', // 電話
        email: user.email || '', // 信箱
        city: user.city || '', // 市
        district: user.district || '', // 地區
        address: user.address || '', // 地址
        // 保持其他欄位不變
        deliveryTime: prev.deliveryTime
      }))
    }
  }, [user])

  useEffect(() => {
    if (isDirectCheckout) {
      // 如果是直接結帳，從 localStorage 獲取臨時結帳項目
      const directCheckoutItem = JSON.parse(
        localStorage.getItem('directCheckoutItem') || '{}'
      )
      // 設置相關狀態
      if (directCheckoutItem.productId) {
        // 設置結帳相關狀態...
      }
    }
  }, [isDirectCheckout])

  // 检查URL参数获取支付结果
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const orderSN = urlParams.get('order_sn')

    if (orderSN) {
      const checkOrderStatus = async () => {
        try {
          const response = await api.get(
            `/api/payments/order-status/${orderSN}`
          )
          const { status } = response.data.data

          if (status === 'completed') {
            setPaymentResult('success')
          } else if (status === 'pending') {
            setPaymentResult('pending')
          } else {
            setPaymentResult('fail')
          }

          setOrderData({ order_sn: orderSN })
          setCurrentStep(2)
        } catch (error) {
          console.error('檢查訂單狀態失敗:', error)
          setPaymentResult('fail')
        }
      }

      checkOrderStatus()
    }
  }, [])

  if (!isOpen) return null

  // 檢查每個步驟是否可以訪問
  const canAccessStep = (stepIndex) => {
    switch (stepIndex) {
      case 0: // 第一步永遠可以訪問
        return true
      case 1: // 第二步需要完成運送和付款方式選擇，或是已經到過第二步以後
        return (selectedDelivery && selectedPayment) || currentStep >= 1
      case 2: // 第三步需要完成前兩步
        if (selectedDelivery === 'store') {
          // 到店自取只需要完成第一步
          return currentStep >= 2
        } else {
          // 宅配需要填寫完收件資訊
          const isRecipientDataComplete =
            recipientData.name &&
            recipientData.phone &&
            recipientData.city &&
            recipientData.district &&
            recipientData.address
          return currentStep >= 2 && isRecipientDataComplete
        }
      default:
        return false
    }
  }

  // 處理步驟點擊
  const handleStepClick = (step) => {
    if (canAccessStep(step)) {
      setCurrentStep(step)
    }
  }

  // 步驟配置
  const steps = [
    {
      title: '付款方式',
      description: '選擇運送與付款方式'
    },
    {
      title: '收件人資料',
      description:
        selectedDelivery === 'store' ? '確認取貨資訊' : '填寫收件資訊'
    },
    {
      title: '確認訂單',
      description: '確認訂單內容'
    }
  ]

  // 修改 Google 登入處理
  const handleGoogleLogin = () => {
    const baseUrl =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:8080'
        : 'https://beautefeel.com'
    // 儲存當前狀態
    localStorage.setItem('checkoutRedirect', 'true')
    window.location.href = `${baseUrl}/api/auth/google`
  }

  // 修改 Line 登入處理
  const handleLineLogin = () => {
    localStorage.setItem('checkoutRedirect', 'true')
    window.location.href = `https://beautefeel.com/api/auth/line`
  }

  // 修改平台登入处理函数
  const handlePlatformLogin = async (e) => {
    e.preventDefault()
    try {
      if (!loginData.username || !loginData.password) {
        throw new Error('請輸入帳號和密碼')
      }

      const loginResult = await login({
        type: 'platform',
        username: loginData.username,
        password: loginData.password
      })

      // 如果登入成功
      if (loginResult?.user) {
        setShowLoginForm(false)
        setRecipientData((prev) => ({
          ...prev,
          name: loginResult.user.name || '',
          phone: loginResult.user.phone || '',
          email: loginResult.user.email || '',
          city: loginResult.user.city || '',
          district: loginResult.user.district || '',
          address: loginResult.user.address || ''
        }))
        Toast.show({
          content: '登入成功',
          duration: 1000
        })
      } else {
        // 如果登入失敗但沒有錯誤訊息
        throw new Error('登入失敗')
      }
    } catch (error) {
      // 不要顯示錯誤訊息，只在控制台記錄
      console.error('登入失敗')
      // 如果你完全不想看到錯誤訊息，可以註釋掉上面這行
    }
  }
  console.log(paymentResult)

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  // 假資料：店家資訊
  const storeInfo = {
    name: 'BeauteFeel 旗艦店',
    address: '台北市大安區忠孝東路四段100號',
    phone: '02-2345-6789',
    businessHours: '週一至週五 10:00-21:00；週六日 11:00-22:00'
  }

  // 運送選項
  const deliveryOptions = [
    {
      id: 'store',
      label: '到店自取',
      description: '可至實體店面取貨',
      fee: 0
    }
    /* 暫時移除宅配選項，後續要開啟時取消註解
    {
      id: 'home',
      label: '宅配到府',
      description: '配送時間約3-5個工作天',
      fee: 150
    }
    */
  ]

  // 根據運送方式顯示不同的付款選項
  const paymentOptions =
    selectedDelivery === 'store'
      ? [
          { id: 'cash', label: '現場付現', fee: 0 }
          /* 暫時移除線上刷卡選項，後續要開啟時取消註解
          { id: 'card', label: '線上刷卡', fee: 0 }
          */
        ]
      : [
          { id: 'cod', label: '貨到付款', fee: 30 }
          /* 暫時移除信用卡選項，後續要開啟時取消註解
          { id: 'credit', label: '信用卡', fee: 0 }
          */
        ]

  const renderDeliveryAndPaymentOptions = () => (
    <Section>
      <SectionTitle>
        選擇運送方式
        <span className='required'>*</span>
      </SectionTitle>
      {deliveryOptions.map((option) => (
        <DeliveryOption
          key={option.id}
          checked={selectedDelivery === option.id}
          onClick={() => setSelectedDelivery(option.id)}
        >
          <input
            type='radio'
            checked={selectedDelivery === option.id}
            onChange={() => setSelectedDelivery(option.id)}
          />
          <div>
            <div>{option.label}</div>
            <small>{option.description}</small>
          </div>
          <span className='fee'>
            {option.fee === 0 ? '免運費' : `NT$ ${option.fee}`}
          </span>
        </DeliveryOption>
      ))}

      {selectedDelivery && (
        <Section>
          <SectionTitle>
            選擇付款方式
            <span className='required'>*</span>
          </SectionTitle>
          {paymentOptions.map((option) => (
            <DeliveryOption
              key={option.id}
              checked={selectedPayment === option.id}
              onClick={() => setSelectedPayment(option.id)}
            >
              <input
                type='radio'
                checked={selectedPayment === option.id}
                onChange={() => setSelectedPayment(option.id)}
              />
              <div>{option.label}</div>
              {option.fee > 0 && <span className='fee'>+NT$ {option.fee}</span>}
            </DeliveryOption>
          ))}
        </Section>
      )}
    </Section>
  )

  // 修改地址表單渲染，根據配送方式顯示不同內容
  const renderAddressForm = () => (
    <Section>
      {selectedDelivery === 'store' ? (
        // 到店自取只顯示會員資訊和店家資訊
        <>
          {/* 會員資訊 */}
          <UserInfoSection>
            <h4>取貨人資訊</h4>
            <InfoItem>
              <label>姓名：</label>
              <span>{user?.name || ''}</span>
            </InfoItem>
            <InfoItem>
              <label>電話：</label>
              <span>{user?.phone || ''}</span>
            </InfoItem>
          </UserInfoSection>

          {/* 店家資訊 */}
          <StoreInfo>
            <h4>取貨門市資訊</h4>
            <p>門市名稱：{storeInfo.name}</p>
            <p>門市地址：{storeInfo.address}</p>
            <p>聯絡電話：{storeInfo.phone}</p>
            <p className='store-hours'>營業時間：{storeInfo.businessHours}</p>
          </StoreInfo>
        </>
      ) : (
        // 宅配顯示完整收件表單
        <>
          <FormInput
            type='text'
            placeholder='收件人姓名'
            value={recipientData.name}
            onChange={(e) =>
              setRecipientData({ ...recipientData, name: e.target.value })
            }
          />
          <FormInput
            type='text'
            placeholder='收件人電話'
            value={recipientData.phone}
            onChange={(e) =>
              setRecipientData({ ...recipientData, phone: e.target.value })
            }
          />
          <FormInput
            type='text'
            placeholder='收件人信箱'
            value={recipientData.email}
            onChange={(e) =>
              setRecipientData({ ...recipientData, email: e.target.value })
            }
          />
        </>
      )}
    </Section>
  )

  const renderPaymentStep = () => {
    if (paymentStatus === 'processing') {
      return (
        <PaymentProcessing>
          <LoadingOutlined spin />
          <h3>正在處理您的支付請求...</h3>
          <p>請稍候,即將跳轉到支付頁面</p>
        </PaymentProcessing>
      )
    }

    if (paymentStatus === 'error') {
      return (
        <PaymentError>
          <CloseCircleOutlined className='error-icon' />
          <h3>支付發起失敗</h3>
          <p>{paymentError || '請稍後重試'}</p>
          <RetryButton
            onClick={() => {
              setPaymentStatus(null)
              setPaymentError(null)
            }}
          >
            重試
          </RetryButton>
        </PaymentError>
      )
    }

    return (
      <Section>
        <PaymentHeader>
          <LockOutlined className='icon' />
          <span>確認付款資訊</span>
          <span className='secure'>安全連線</span>
        </PaymentHeader>

        <OrderInfo>
          <h4>訂單資訊</h4>
          <InfoRow>
            <span>商品金額</span>
            <span>NT$ {total.toLocaleString()}</span>
          </InfoRow>
          <InfoRow>
            <span>運費</span>
            <span>{selectedDelivery === 'home' ? 'NT$ 150' : '免運費'}</span>
          </InfoRow>
          <InfoRow className='total'>
            <span>應付總額</span>
            <span>
              NT${' '}
              {(
                total + (selectedDelivery === 'home' ? 150 : 0)
              ).toLocaleString()}
            </span>
          </InfoRow>
        </OrderInfo>

        <PaymentActions>
          <PaymentButton onClick={handlePayment}>確認付款</PaymentButton>
          <ChangePaymentButton onClick={() => setCurrentStep(0)}>
            更改付款方式
          </ChangePaymentButton>
        </PaymentActions>

        <PaymentNotice>
          <InfoCircleOutlined />
          <span>您即將使用信用卡付款,完成後將返回商店。</span>
        </PaymentNotice>
      </Section>
    )
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return renderDeliveryAndPaymentOptions()
      case 1:
        return user ? (
          <Section>
            <SectionTitle>填寫收件資訊</SectionTitle>
            {renderAddressForm()}
          </Section>
        ) : (
          renderLoginOptions()
        )
      case 2:
        if (paymentResult) {
          return (
            <Section>
              {paymentResult === 'success' ? (
                <OrderSuccessMessage>
                  <CheckCircleOutlined className='success-icon' />
                  <h3>付款成功！</h3>
                  <p>訂單編號：{orderData?.order_sn}</p>
                  <OrderDetails>
                    <p>付款金額：NT$ {total.toLocaleString()}</p>
                  </OrderDetails>
                  <ActionButton
                    onClick={() => {
                      onClose()
                      navigate('/user-appointment-list/all/1')
                    }}
                  >
                    查看訂單
                  </ActionButton>
                </OrderSuccessMessage>
              ) : paymentResult === 'pending' ? (
                <OrderPendingMessage>
                  <ClockCircleOutlined className='pending-icon' />
                  <h3>訂單處理中</h3>
                  <p>訂單編號：{orderData?.order_sn}</p>
                  <ActionButton onClick={onClose}>返回商店</ActionButton>
                </OrderPendingMessage>
              ) : (
                <OrderSuccessMessage>
                  <CloseCircleOutlined className='fail-icon' />
                  <h3>付款失敗</h3>
                  <p>很抱歉，您的付款未能完成</p>
                  <ActionButton onClick={() => setPaymentResult(null)}>
                    重新付款
                  </ActionButton>
                  <BackButton onClick={onClose}>返回購物車</BackButton>
                </OrderSuccessMessage>
              )}
            </Section>
          )
        }
        return selectedPayment === 'credit'
          ? renderPaymentStep()
          : renderOrderConfirmation()
      default:
        return null
    }
  }

  // 修改支付處理函數
  const handlePayment = async () => {
    try {
      setPaymentStep('processing')

      // 生成訂單編號 (使用時間戳 + 隨機數)
      const orderSN = `ORDER${Date.now()}${Math.floor(Math.random() * 1000)}`

      // 準備商品描述 (將所有商品名稱組合，限制長度)
      const itemDesc = Object.values(cartData.stores)
        .flatMap((store) => store.items.map((item) => item.title))
        .join(', ')
        .slice(0, 200) // 限制長度，避免過長

      // 準備訂單資料
      const newOrderData = {
        order_sn: orderSN,
        email: recipientData.email || user?.email,
        amount: total,
        itemDesc: itemDesc,
        businessId: cartData.items[0].businessId,
        products: cartData.items.map((item) => ({
          product: item.productId,
          quantity: item.quantity,
          price: item.price,
          title: item.title
        })),
        shipping_fee: 0,
        payment_method: selectedPayment,
        shipping_method: selectedDelivery,
        recipient_info: {
          name: recipientData.name || user?.name,
          phone: recipientData.phone || user?.phone,
          email: recipientData.email || user?.email
        }
      }

      // 根據付款方式處理訂單
      if (selectedPayment === 'cash') {
        // 現場付現：直接建立訂單
        const response = await api.post('/api/payments/orders', newOrderData)
        const orderData = response.data

        // 更新訂單狀態
        setOrderData(orderData)
        setPaymentResult('success')
        setCurrentStep(2)
      } else if (selectedPayment === 'credit') {
        /* 暫時移除信用卡支付邏輯，後續要開啟時取消註解
        // 信用卡支付：發送到金流
        const response = await api.post('/api/payments/pay', newOrderData)
        const paymentData = await response.data

        // 建立隱藏的表單來處理支付
        const form = document.createElement('form')
        form.method = 'POST'
        form.action = 'https://ccore.newebpay.com/MPG/mpg_gateway'
        form.style.display = 'none'

        for (const [key, value] of Object.entries(paymentData)) {
          const input = document.createElement('input')
          input.type = 'hidden'
          input.name = key
          input.value = value
          form.appendChild(input)
        }

        document.body.appendChild(form)
        form.submit()
        */
      }
    } catch (error) {
      console.error('訂單建立失敗:', error)
      setPaymentStep('error')
      setPaymentError(error.message || '訂單建立失敗，請稍後重試')
    }
  }

  // 修改登入選項渲染
  const renderLoginOptions = () => (
    <LoginSection>
      <h3>請先登入會員</h3>
      <LoginButton className='google' onClick={handleGoogleLogin}>
        <FaGoogle />
        使用 Google 登入
      </LoginButton>
      <LoginButton className='line' onClick={handleLineLogin}>
        <FaLine />
        使用 Line 登入
      </LoginButton>
      <LoginButton
        className='platform'
        onClick={() => setShowLoginForm(!showLoginForm)}
      >
        <UserOutlined />
        平台會員登入
      </LoginButton>

      {showLoginForm && (
        <LoginForm onSubmit={handlePlatformLogin}>
          <FormInput
            type='text'
            placeholder='帳號'
            value={loginData.username}
            onChange={(e) =>
              setLoginData({ ...loginData, username: e.target.value })
            }
            required
          />
          <FormInput
            type='password'
            placeholder='密碼'
            value={loginData.password}
            onChange={(e) =>
              setLoginData({ ...loginData, password: e.target.value })
            }
            required
          />
          <NextButton type='submit'>登入</NextButton>
        </LoginForm>
      )}
    </LoginSection>
  )

  // 訂單確認頁面渲染
  const renderOrderConfirmation = () => (
    <Section>
      <OrderSuccessMessage>
        <CheckCircleOutlined className='success-icon' />
        <h3>訂單已成功建立！</h3>
        <p>訂單編號：{orderData?.order_sn || '123'}</p>
      </OrderSuccessMessage>

      <OrderSummary>
        <h4>訂單明細</h4>
        <OrderDetail>
          <div className='detail-row'>
            <span>收件人</span>
            <span>
              {recipientData.name}{' '}
              {recipientData.gender === 'mr'
                ? '先生'
                : recipientData.gender === 'ms'
                ? '小姐'
                : ''}
            </span>
          </div>
          <div className='detail-row'>
            <span>聯絡電話</span>
            <span>{recipientData.phone}</span>
          </div>
          <div className='detail-row'>
            <span>電子信箱</span>
            <span>{recipientData.email}</span>
          </div>
          <div className='detail-row'>
            <span>配送地址</span>
            <span>
              {selectedDelivery === 'store'
                ? storeInfo.address
                : `${recipientData.city}${recipientData.district}${recipientData.address}`}
            </span>
          </div>
          <div className='detail-row'>
            <span>配送方式</span>
            <span>
              {selectedDelivery === 'store' ? '到店自取' : '宅配到府'}
            </span>
          </div>
          <div className='detail-row'>
            <span>付款方式</span>
            <span>
              {selectedPayment === 'credit'
                ? '信用卡'
                : selectedPayment === 'cod'
                ? '貨到付款'
                : selectedPayment === 'cash'
                ? '現場付現'
                : '現場刷卡'}
            </span>
          </div>
          {recipientData.note && (
            <div className='detail-row'>
              <span>備註</span>
              <span>{recipientData.note}</span>
            </div>
          )}
        </OrderDetail>

        <OrderAmount>
          <div className='amount-row'>
            <span>商品金額</span>
            <span>NT$ {total.toLocaleString()}</span>
          </div>
          <div className='amount-row'>
            <span>運費</span>
            <span>{selectedDelivery === 'home' ? 'NT$ 100' : '免運費'}</span>
          </div>
          <div className='amount-row total'>
            <span>總計</span>
            <span>
              NT${' '}
              {(
                total + (selectedDelivery === 'home' ? 100 : 0)
              ).toLocaleString()}
            </span>
          </div>
        </OrderAmount>
      </OrderSummary>

      <NextButton onClick={onClose}>完成</NextButton>
    </Section>
  )

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose} />
        <h2>訂購只要三步驟</h2>

        {/* 修改 Steps 組件 */}
        <StyledSteps
          current={currentStep}
          items={steps.map((step, index) => ({
            ...step,
            className: canAccessStep(index) ? 'can-click' : '',
            disabled: !canAccessStep(index) // 添加 disabled 屬性
          }))}
          onChange={handleStepClick}
        />

        {/* 如果已登入顯示用戶資訊 */}
        {user && (
          <UserInfo>
            <span className='icon'>
              <UserOutlined />
            </span>
            <span className='name'>{user.name || '會員'}</span>
            <span className='member-tag'>已登入會員</span>
          </UserInfo>
        )}

        {renderStepContent()}

        {/* 底部按鈕 */}
        <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
          {currentStep > 0 && currentStep < steps.length - 1 && (
            <NextButton
              onClick={() => setCurrentStep(currentStep - 1)}
              style={{ flex: 1 }}
            >
              上一步
            </NextButton>
          )}
          {currentStep < steps.length - 1 && (
            <NextButton
              onClick={() => setCurrentStep(currentStep + 1)}
              style={{ flex: 1 }}
              disabled={
                (currentStep === 0 &&
                  (!selectedDelivery || !selectedPayment)) ||
                (currentStep === 1 && !user) ||
                (currentStep === 1 &&
                  selectedDelivery !== 'store' &&
                  !recipientData.name) ||
                currentStep === steps.length - 1
              }
            >
              {currentStep === steps.length - 1 ? '確認結帳' : '下一步'}
            </NextButton>
          )}
        </div>

        <TotalAmount>
          <div>總計</div>
          <div className='amount'>
            NT${' '}
            {(total + (selectedDelivery === 'home' ? 100 : 0)).toLocaleString()}
          </div>
        </TotalAmount>
      </ModalContent>
    </ModalOverlay>
  )
}

const SaveAddressCheckbox = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 8px;

  input[type='checkbox'] {
    width: auto;
  }

  label {
    color: #666;
    cursor: pointer;
  }
`

// 新增樣式組件
const AddressRow = styled.div`
  display: flex;
  gap: 10px;
  margin: 8px 0;
`

const GenderSelection = styled.div`
  margin: 8px 0;

  label {
    margin-right: 10px;
    color: #666;
  }
`

const RadioGroup = styled.div`
  display: inline-flex;
  gap: 20px;
  margin-left: 10px;
`

const RadioLabel = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  input[type='radio'] {
    margin: 0;
    cursor: pointer;
  }

  span {
    color: #333;
  }
`

const FormTextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  min-height: 100px;
  resize: vertical;
`

const PaymentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  .icon {
    color: #52c41a;
    font-size: 20px;
  }

  .secure {
    color: #52c41a;
    font-size: 14px;
    margin-left: auto;
  }
`

const PaymentNotice = styled.div`
  background: #fff5f5;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  color: #d43f5a;
`

const OrderInfo = styled.div`
  background: #f8f8f8;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;

  h4 {
    margin: 0 0 10px 0;
    color: #333;
  }

  p {
    margin: 5px 0;
    color: #666;
  }
`

const PaymentActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
`

const PaymentButton = styled.button`
  width: 100%;
  padding: 15px;
  background: #e14a63;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #d43f5a;
  }
`

const ChangePaymentButton = styled.button`
  width: 100%;
  padding: 15px;
  background: #f0f0f0;
  color: #333;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #e0e0e0;
  }
`

const CustomerService = styled.div`
  text-align: center;
  margin-top: 20px;
  color: #666;

  button {
    background: none;
    border: none;
    color: #1890ff;
    cursor: pointer;
    padding: 0;
    margin-left: 5px;
    text-decoration: underline;

    &:hover {
      color: #40a9ff;
    }
  }
`

const OrderSuccessMessage = styled.div`
  text-align: center;
  margin: 20px 0;

  .success-icon {
    font-size: 48px;
    color: #52c41a;
  }

  h3 {
    margin: 10px 0;
    color: #333;
  }

  p {
    color: #666;
  }
`
const OrderPendingMessage = styled(OrderSuccessMessage)`
  h3 {
    color: #ff4d4f;
  }
  .pending-icon {
    font-size: 48px;
    color: #ff4d4f;
  }
`

const OrderSummary = styled.div`
  background: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;

  h4 {
    margin: 0 0 15px 0;
    color: #333;
  }
`

const OrderDetail = styled.div`
  .detail-row {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    span:first-child {
      color: #666;
    }

    span:last-child {
      color: #333;
      text-align: right;
    }
  }
`

const OrderAmount = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ddd;

  .amount-row {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    &.total {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #ddd;
      font-weight: bold;

      span:last-child {
        color: #ff4d6d;
        font-size: 1.2em;
      }
    }
  }
`

// 新增返回按鈕樣式
const BackToLoginOptions = styled.button`
  background: none;
  border: none;
  color: #666;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 15px;

  &:hover {
    color: #333;
  }
`

// 新增樣式組件
const UserInfoSection = styled.div`
  background: #f8f8f8;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;

  h4 {
    margin: 0 0 10px 0;
    color: #333;
  }
`

const InfoItem = styled.div`
  display: flex;
  margin: 8px 0;

  label {
    color: #666;
    min-width: 60px;
  }

  span {
    color: #333;
  }
`

const PaymentProcessing = styled.div`
  text-align: center;
  padding: 40px 20px;

  .anticon {
    font-size: 40px;
    color: #1890ff;
    margin-bottom: 20px;
  }

  h3 {
    margin: 0 0 10px;
    color: #333;
  }

  p {
    color: #666;
  }
`

const PaymentError = styled.div`
  text-align: center;
  padding: 40px 20px;

  .error-icon {
    font-size: 40px;
    color: #ff4d4f;
    margin-bottom: 20px;
  }

  h3 {
    margin: 0 0 10px;
    color: #333;
  }

  p {
    color: #666;
    margin-bottom: 20px;
  }
`

const RetryButton = styled.button`
  padding: 8px 24px;
  background: #ff4d4f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #ff7875;
  }
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;

  &.total {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #eee;
    font-weight: bold;

    span:last-child {
      color: #ff4d6d;
      font-size: 1.2em;
    }
  }
`

const ResultWrapper = styled.div`
  text-align: center;
  padding: 40px 20px;

  .success-icon {
    font-size: 48px;
    color: #52c41a;
  }

  .fail-icon {
    font-size: 48px;
    color: #ff4d4f;
  }

  .error-icon {
    font-size: 48px;
    color: #faad14;
  }

  h3 {
    margin: 16px 0;
    font-size: 24px;
  }

  p {
    color: #666;
    margin-bottom: 24px;
  }
`

const OrderDetails = styled.div`
  background: #f5f5f5;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 24px;

  p {
    margin: 8px 0;
    color: #333;
  }
`

const ActionButton = styled.button`
  padding: 8px 24px;
  background: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background: #40a9ff;
  }
`

const BackButton = styled.button`
  padding: 8px 24px;
  background: white;
  color: #666;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 16px;
  font-size: 16px;

  &:hover {
    border-color: #40a9ff;
    color: #40a9ff;
  }
`

const ProcessingWrapper = styled.div`
  text-align: center;
  padding: 40px 20px;

  .loading-icon {
    font-size: 48px;
    color: #1890ff;
  }

  h3 {
    margin: 16px 0;
    font-size: 24px;
  }

  p {
    color: #666;
  }
`

// PropTypes 驗證
CheckoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  cartData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired,
  isDirectCheckout: PropTypes.bool
}

export default CheckoutModal
