import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Typography, Tabs, Flex, Divider, Modal, message, Empty } from 'antd'
import axios from 'axios'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import { MapPin } from 'react-feather'
import StylistPortfolio from '../components/StylistPortfolio'
import { HeartOutlined, RightOutlined, HeartFilled } from '@ant-design/icons'
import { categories } from '../utils/utils'
import StylistService from '../components/StylistService'
import Reviews from '../components/Reviews'
import { motion } from 'framer-motion'
import Loading from '../utils/Loading'
import { useAuth } from '../hooks/AuthContext'
import api from '../utils/api'
import moment from 'moment'
import { Helmet } from 'react-helmet-async'
const { Title, Text } = Typography

const ProfileContainer = styled(motion.div)`
  max-width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: linear-gradient(135deg, #fff5f7, #fce4ec);
  /* background: #fafafa; */

  @media (min-width: 821px) {
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-gap: 30px;
  }
  @media (max-width: 820px) {
    background: #fff;
  }
`

const SidebarContainer = styled.div`
  /* padding: 20px; */
  @media (min-width: 821px) {
    margin: 10px 0;
    background-color: #fff;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    padding: 0px;
    position: sticky;
    left: 20px;
    border-radius: 20px;
    overflow-y: auto;
  }
`

const MainContentContainer = styled.div`
  padding: 20px;
  @media (min-width: 821px) {
    padding: 20px 20px 20px 10px;
    overflow-y: auto;
  }
`
const ProfileHeader = styled.div`
  position: relative;
  height: auto;
  overflow: visible;

  @media (min-width: 821px) {
    margin-bottom: 10px;
    height: 200px;
    border-radius: 20px;
    overflow: hidden;
  }

  @media (max-width: 820px) {
    height: 60vh;
  }
`

const AvatarContainer = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;

  @media (max-width: 820px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
    box-shadow: none;
    transform: none;
  }
`

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  background-color: #f0f0f0;

  @media (max-width: 820px) {
    object-position: center 30%;
  }
`

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  @media (max-width: 820px) {
    background-color: #fff;
    border-radius: 30px 30px 0 0;
    margin-top: -30px;
    padding: 20px 20px 0px 20px;
    position: relative;
    z-index: 1;
    flex-direction: row;
    justify-content: space-around;
    border-radius: 30px 30px 0 0;
  }
  @media (max-width: 475px) {
    flex-direction: column;
  }
`

const ProfileName = styled.h2`
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
  @media (max-width: 475px) {
    font-size: 20px;
  }
`

const ProfileUsername = styled.div`
  color: #666;
  margin: 10px 0px 15px 0px;
  font-size: 16px;
  font-weight: 400;
  align-self: flex-start;
`

const FollowButton = styled.button`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px 80px;
  background: linear-gradient(to right, #ff385c, #f78659);
  border: none;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  :hover {
    opacity: 0.8;
  }
  @media (max-width: 820px) {
    padding: 10px 70px;
    border-radius: 30px;
  }
  @media (max-width: 475px) {
    padding: 10px 40px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 30px;
  }
`

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;
  @media (max-width: 821px) {
    margin: 10px 20px 20px 20px;
  }
`

const StatItem = styled(motion.div)`
  text-align: center;
  /* background: linear-gradient(135deg, #fce4ec, #f8bbd0); */
  background: #f0f0f0;
  border-radius: 10px;
  padding: 0 5px 5px 5px;
  flex: 1;
  margin: 0 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
`

const LinkItem = styled.div`
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  margin: 0px 20px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 820px) {
    margin: 0 25px 10px 25px;
  }
`

const TabsContainer = styled(Tabs)`
  .ant-tabs-nav {
    background-color: #fff;
    border-radius: 30px 30px 0 0;
    padding: 10px 20px;
    margin: 0;
  }

  .ant-tabs-nav::before {
    /* border-bottom: none; */
  }
  .ant-tabs-tab {
    padding: 12px 0;
  }
  .ant-tabs-tab-btn {
    font-size: 16px;
    font-weight: bold;
  }
  @media (max-width: 475px) {
    .ant-tabs-tab-btn {
      font-size: 14px;
    }
    .ant-tabs-tab {
      padding: 5px 0;
    }
    .ant-tabs-nav {
      padding: 0px 10px;
    }
  }
`

const ProfileFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 20px;

  @media (max-width: 820px) {
    width: 100%;
    margin-left: 10px;
  }
`

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  @media (max-width: 820px) {
    background-color: #fafafa;
  }
`

const SkillTag = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px 10px;
  background: linear-gradient(135deg, #e1bee7, #ce93d8);
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`
const ReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 0 0 20px 20px;
  @media (max-width: 820px) {
    background-color: #fafafa;
  }
`

const ExperienceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 0 0 20px 20px;
  @media (max-width: 820px) {
    background-color: #fafafa;
  }
`

const ExperienceItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const AvatarCircle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: white;
`

const ExperienceInfo = styled.div`
  flex: 1;
  text-align: left;
`

const CompanyName = styled.h3`
  margin: 0;
  font-size: 18px;
`

const JobTitle = styled.p`
  margin: 5px 0;
  font-size: 16px;
`

const Duration = styled.p`
  margin: 0;
  color: #666;
  font-size: 14px;
`

const AboutContainer = styled.div`
  padding: 20px;
  text-align: left;
  background-color: #ffffff;
  border-radius: 0 0 20px 20px;
  @media (max-width: 820px) {
    background-color: #fafafa;
  }
`

const AboutTitle = styled.h3`
  margin-bottom: 15px;
`

const AboutText = styled.p`
  margin-bottom: 20px;
  line-height: 1.6;
`

const SkillsTitle = styled.h3`
  margin-bottom: 15px;
`

const Location = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  align-self: flex-start;
`

const StylistType = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  /* margin-bottom: 10px; */
  background-color: #e6f5ff;
  padding: 0px 8px;
  border-radius: 10px;
  @media (max-width: 820px) {
    padding: 0 10px;
    margin-bottom: 0px;
  }
`

const StylistProfile = () => {
  const { username } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [stylistData, setStylistData] = useState(null)
  const [businessData, setBusinessData] = useState(null)
  const [activeTab, setActiveTab] = useState('1')
  const [isAvatarModalVisible, setIsAvatarModalVisible] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)
  const { user } = useAuth()
  const [favoriteCount, setFavoriteCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [experiences, setExperiences] = useState([])
  const [reviewsData, setReviewsData] = useState([])

  useEffect(() => {
    const fetchStylistData = async () => {
      try {
        setIsLoading(true)
        const response = await axios.get(
          `${API_BASE_URL}/api/business_C/stylist/${username}`
        )
        setStylistData(response.data.stylist)
        setBusinessData(response.data.business)

        // 獲取經歷數據
        const experiencesResponse = await axios.get(
          `${API_BASE_URL}/api/stylist/${response.data.stylist._id}/experience`
        )
        setExperiences(experiencesResponse.data.experiences)

        setIsLoading(false)
      } catch (error) {
        console.error('獲取造型師數據失敗:', error)
        setIsError(true)
        setIsLoading(false)
      }
    }

    if (username) {
      fetchStylistData()
    }
  }, [username])

  useEffect(() => {
    // 根據當前 URL 路徑設置活動標籤
    const path = location.pathname.split('/').pop()
    switch (path) {
      case 'portfolio':
        setActiveTab('1')
        break
      case 'about':
        setActiveTab('2')
        break
      case 'experience':
        setActiveTab('3')
        break
      case 'services':
        setActiveTab('4')
        break
      case 'reviews':
        setActiveTab('5')
        break
      default:
        setActiveTab('1') // 默認設置為作品標籤
    }
  }, [username, location.pathname])

  useEffect(() => {
    if (stylistData) {
      setFavoriteCount(stylistData.favoritedBy.length || 0)
    }
  }, [stylistData])

  useEffect(() => {
    // 如果用戶已登錄且有造型師數據，檢查收藏狀態
    if (user && stylistData) {
      const checkFavoriteStatus = async () => {
        try {
          const response = await api.get(
            `api/user/${user.userId}/favorite-designers`
          )
          const favoriteDesigners = response.data.favoriteDesigners
          setIsFavorite(
            favoriteDesigners.some(
              (designer) => designer._id === stylistData._id
            )
          )
        } catch (error) {
          console.error('檢查收藏狀態失敗:', error)
        }
      }

      checkFavoriteStatus()
    }
  }, [user, stylistData])

  const handleFavorite = async () => {
    if (!user) {
      navigate('/login')
      return
    }

    try {
      if (isFavorite) {
        await api.delete(
          `${API_BASE_URL}/api/user/${user.userId}/favorite-designers/${stylistData._id}`
        )
        setIsFavorite(false)
        setFavoriteCount((prevCount) => prevCount - 1)
        message.success('已取消收藏')
      } else {
        await api.post(
          `${API_BASE_URL}/api/user/${user.userId}/favorite-designers/${stylistData._id}`
        )
        setIsFavorite(true)
        setFavoriteCount((prevCount) => prevCount + 1)
        message.success('已添加收藏')
      }
    } catch (error) {
      console.error('收藏操作失敗:', error)
      message.error('操作失敗，請稍後再試')
    }
  }

  const handleTabChange = (key) => {
    setActiveTab(key)
    switch (key) {
      case '1':
        navigate(`/${username}/portfolio`)
        break
      case '2':
        navigate(`/${username}/about`)
        break
      case '3':
        navigate(`/${username}/experience`)
        break
      case '4':
        navigate(`/${username}/services`)
        break
      case '5':
        navigate(`/${username}/reviews`)
        break
      default:
        navigate(`/${username}/portfolio`) // 默認導航到作品頁面
    }
  }

  // 格式化日期範圍的函數
  const formatDateRange = (startDate, endDate, isCurrent) => {
    const start = moment(startDate).format('YYYY年MM月')
    if (isCurrent) {
      return `${start} - 至今`
    }
    const end = endDate ? moment(endDate).format('YYYY年MM月') : '至今'
    return `${start} - ${end}`
  }

  if (isLoading) {
    return <Loading />
  }

  if (isError || !stylistData) {
    return (
      <Empty
        image={`${PHOTO_URL}/public/assets/nodata.svg`}
        imageStyle={{
          height: 100
        }}
        style={{ margin: '50px' }}
        description='沒有符合條件的商家'
      />
    )
  }

  const tabItems = [
    {
      key: '1',
      label: `${stylistData.portfolio?.length || 0} 個作品`,
      children: activeTab === '1' && <StylistPortfolio username={username} />
    },
    {
      key: '2',
      label: '關於',
      children: activeTab === '2' && (
        <AboutContainer>
          <AboutTitle>關於我</AboutTitle>
          <AboutText>
            {stylistData.bio ? (
              stylistData.bio.split('\r\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < stylistData.bio.split('\r\n').length - 1 && <br />}
                </React.Fragment>
              ))
            ) : (
              <span>暫無簡介</span>
            )}
          </AboutText>
          <Divider />

          <SkillsTitle>擅長領域</SkillsTitle>
          <SkillsContainer>
            {stylistData.skillsType && stylistData.skillsType.length > 0 ? (
              stylistData.skillsType.map((skill) => {
                const category = categories.find((cat) => cat.type === skill)
                return category ? (
                  <SkillTag
                    key={skill}
                    whileHover={{ scale: 1.1, rotate: 5 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <span>{category.name}</span>
                  </SkillTag>
                ) : null
              })
            ) : (
              <Empty description='暫無擅長領域' />
            )}
          </SkillsContainer>
          <Divider />
          <SkillsTitle>特色標籤</SkillsTitle>
          <SkillsContainer>
            {stylistData.tags && stylistData.tags.length > 0 ? (
              stylistData.tags.map((tag) => (
                <SkillTag
                  key={tag}
                  whileHover={{ scale: 1.1, rotate: 5 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <span>#{tag}</span>
                </SkillTag>
              ))
            ) : (
              <Empty description='暫無特色標籤' />
            )}
          </SkillsContainer>
        </AboutContainer>
      )
    },
    {
      key: '3',
      label: '經歷',
      children: activeTab === '3' && (
        <ExperienceContainer>
          {experiences.length > 0 ? (
            experiences.map((exp, index) => (
              <ExperienceItem key={index}>
                <AvatarCircle style={{ backgroundColor: exp.color }}>
                  {exp.company.slice(0, 2)}
                </AvatarCircle>
                <ExperienceInfo>
                  <CompanyName>{exp.company}</CompanyName>
                  <JobTitle>{exp.title}</JobTitle>
                  <Duration>
                    {formatDateRange(exp.startDate, exp.endDate, exp.isCurrent)}{' '}
                    | {exp.location}
                  </Duration>
                </ExperienceInfo>
              </ExperienceItem>
            ))
          ) : (
            <Empty style={{ marginTop: '50px' }} description='暫無經歷數據' />
          )}
        </ExperienceContainer>
      )
    },
    {
      key: '4',
      label: '服務項目',
      children: activeTab === '4' && (
        <StylistService
          stylistId={stylistData._id}
          businessId={businessData.id}
          businessUsername={businessData.username}
        />
      )
    },
    {
      key: '5',
      label: '評價',
      children: activeTab === '5' && <Reviews stylistId={stylistData._id} />
    }
  ]

  return (
    <ProfileContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Helmet>
        <title>{`${stylistData.name}｜設計師線上預約 - 美感｜BeauteFeel`}</title>
        <meta
          name='description'
          content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <SidebarContainer>
        <ProfileHeader>
          <AvatarContainer onClick={() => setIsAvatarModalVisible(true)}>
            <AvatarImage
              src={
                stylistData.avatar
                  ? `${PHOTO_URL}${stylistData.avatar}`
                  : '/assets/pink2.png'
              }
              alt={stylistData.name}
            />
          </AvatarContainer>
        </ProfileHeader>
        <ProfileInfo>
          <ProfileFlex>
            <Flex>
              <ProfileName>{stylistData.name}</ProfileName>
            </Flex>
            <StylistType>{stylistData.employeeType}</StylistType>
            <ProfileUsername>@{stylistData.username}</ProfileUsername>
            <Location>
              <MapPin size={16} />
              {stylistData.area && stylistData.area.length > 0
                ? `${stylistData.area[0]} ${stylistData.area[1]}`
                : '臺灣'}
            </Location>
          </ProfileFlex>

          <FollowButton onClick={handleFavorite}>
            {isFavorite ? (
              <HeartFilled style={{ marginRight: '5px' }} />
            ) : (
              <HeartOutlined style={{ marginRight: '5px' }} />
            )}
            {isFavorite ? '已收藏' : '收藏'}
          </FollowButton>
        </ProfileInfo>

        <StatsContainer>
          <StatItem>
            <Title style={{ margin: '5px 0', color: '#d5728a' }} level={4}>
              {stylistData.portfolio?.length || 0}
            </Title>
            <Text style={{ fontWeight: 'bold' }}>作品</Text>
          </StatItem>
          <StatItem>
            <Title style={{ margin: '5px 0', color: '#d5728a' }} level={4}>
              {favoriteCount}
            </Title>
            <Text style={{ fontWeight: 'bold' }}>收藏</Text>
          </StatItem>
          <StatItem>
            <Title style={{ margin: '5px 0', color: '#d5728a' }} level={4}>
              {stylistData.reviewCount || 0}
            </Title>
            <Text style={{ fontWeight: 'bold' }}>評價</Text>
          </StatItem>
        </StatsContainer>

        <LinkItem onClick={() => navigate(`/store/${businessData.username}`)}>
          <Text>{businessData.name}</Text>
          <RightOutlined />
        </LinkItem>

        {/* <BioText>
          {stylistData.bio.split('\r\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < stylistData.bio.split('\r\n').length - 1 && <br />}
            </React.Fragment>
          ))}
        </BioText> */}
      </SidebarContainer>

      <MainContentContainer>
        <TabsContainer
          activeKey={activeTab}
          onChange={handleTabChange}
          items={tabItems}
        />
      </MainContentContainer>

      <Modal
        open={isAvatarModalVisible}
        onCancel={() => setIsAvatarModalVisible(false)}
        footer={null}
        width={600}
      >
        <img
          src={`${PHOTO_URL}${stylistData.avatar}`}
          alt={stylistData.name}
          style={{ width: '100%', borderRadius: '10px' }}
        />
      </Modal>
    </ProfileContainer>
  )
}

export default StylistProfile
