import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import { Tag } from 'antd'

const Container = styled.div``

const MainContent = styled.div``

const PhotoWall = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 6px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
    padding: 4px;
  }
`

const PhotoItem = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
  background-color: #f6f6f6;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 768px) {
    border-radius: 0;
    box-shadow: none;
  }
`

const PhotoImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
`

const PhotoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0px 5px;
  opacity: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 768px) {
    opacity: 1;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 70%
    );
  }
`

const StylistName = styled.h3`
  color: white;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const ServiceTag = styled(Tag)`
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 20px;
  margin-right: 12px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 820px) {
    font-size: 10px;
    padding: 3px 7px;
    margin-right: 4px;
  }
  @media (max-width: 480px) {
    font-size: 8px;
    padding: 0 2px;
    margin-right: 2px;
    margin-bottom: 2px;
    border-radius: 10px;
  }
`

const ServiceTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0px 0px 20px 0px;
  @media (max-width: 820px) {
    margin: 5px 0 15px 0;
  }
`

const StoreStylists = ({}) => {
  const navigate = useNavigate()
  const [stylists, setStylists] = useState([])
  const { username } = useParams()

  useEffect(() => {
    const fetchStylists = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/business_C/${username}`
        )
        setStylists(response.data.stylists)
      } catch (error) {
        console.error('獲取設計師數據時出錯:', error)
      }
    }

    fetchStylists()
  }, [username])

  return (
    <Container>
      <MainContent>
        <PhotoWall>
          {stylists.map((stylist) => (
            <PhotoItem
              key={stylist._id}
              onClick={() => navigate(`/${stylist.username}`)}
            >
              <PhotoImage
                src={`${PHOTO_URL}${stylist.avatar}`}
                alt={stylist.name}
              />
              <PhotoOverlay>
                <StylistName>{stylist.name.slice(0, 10) + '...'}</StylistName>
                <ServiceTagsContainer>
                  {stylist.tags &&
                    stylist.tags.slice(0, 2).map((tag, idx) => (
                      <ServiceTag key={idx} color='#d5728a'>
                        {tag}
                      </ServiceTag>
                    ))}
                </ServiceTagsContainer>
              </PhotoOverlay>
            </PhotoItem>
          ))}
        </PhotoWall>
      </MainContent>
    </Container>
  )
}

export default StoreStylists
