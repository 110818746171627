import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { PHOTO_URL } from '../utils/apiutils'
import { ArrowLeft, Search } from 'react-feather'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { message, Button } from 'antd'
import api from '../utils/api'
import { useAuth } from '../hooks/AuthContext'

const LeftPane = styled.div`
  flex: 2;
  padding: 20px;
  border-right: 1px solid #eee;
  @media (max-width: 820px) {
    border-right: none;
    padding: 0px;
  }
`

const EmployeeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 20px 0;

  @media (min-width: 821px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const SectionTitle = styled.h2`
  color: #d5728a;
  margin-bottom: 20px;
`

const SearchInput = styled.input`
  flex: 1;
  border: none;
  padding: 10px;
  font-size: 16px;
  background: transparent;
  &:focus {
    outline: none;
  }
`

const SearchIcon = styled(Search)`
  color: #d5728a;
  margin-right: 10px;
`

const EmptyState = styled.div`
  text-align: center;
  color: #888;
  margin-top: 20px;
`

const EmployeeCard = styled(motion.div)`
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`

const EmployeeAvatar = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
  background-color: #f6f6f6;

  @media (min-width: 768px) {
    height: 180px;
  }
`

const StylistInfo = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: space-between;
  height: 180px; // 添加固定高度

  @media (max-width: 480px) {
    height: 150px;
  }
`

const StylistName = styled.h3`
  font-size: 16px;
  color: #333;
  margin: 0 0 5px 0;

  @media (min-width: 768px) {
    font-size: 18px;
    margin-bottom: 8px;
  }
`

const StylistTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`

const StylistTag = styled.span`
  background-color: #f8e1e7;
  color: #d5728a;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 10px;
  margin-right: 4px;
  margin-bottom: 4px;

  @media (min-width: 768px) {
    font-size: 12px;
    padding: 3px 8px;
  }
`

const StylistDescription = styled.p`
  font-size: 12px;
  color: #666;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`
const SelectButton = styled.button`
  width: 100%;
  background-color: #d5728a;
  color: white;
  border: none;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  margin-top: auto; // 將按鈕推到底部

  &:hover {
    background-color: #c4617a;
  }

  @media (min-width: 768px) {
    padding: 10px;
    font-size: 16px;
  }
`

const BackButton = styled.button`
  display: flex;
  padding: 5px 10px;
  align-items: center;
  background: none;
  color: #666;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 16px;
`

const DEFAULT_AVATAR = `/public/assets/default_avatar.png`
const DEFAULT_STYLIST = {
  _id: null,
  name: '不指定設計師',
  avatar: DEFAULT_AVATAR,
  businessName: '',
  tags: []
}

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #f2b3c0;
  border-radius: 25px;
  padding: 5px 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:focus-within {
    box-shadow: 0 2px 10px rgba(213, 114, 138, 0.2);
    border-color: #d5728a;
  }
`

// 新增一個函數來處理標籤
const getDisplayTags = (tags) => {
  return tags.slice(0, 3)
}

// 添加新的styled组件用于Modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
`

const ModalTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`

const ModalText = styled.p`
  margin-bottom: 10px;
`

const ConfirmButton = styled.button`
  width: 100%;
  background-color: #d5728a;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
`

// 修改判断函数
const shouldShowDeposit = (depositSettings, visitCount) => {
  if (!depositSettings?.isEnabled) return false

  // 所有人都需要定金
  if (depositSettings.depositCondition === 'all') return true

  // 其他情况根据访问次数判断
  switch (depositSettings.depositCondition) {
    case 'newCustomer':
      return visitCount === 0
    case 'visitedOnce':
      return visitCount <= 1
    case 'visitedTwice':
      return visitCount <= 2
    case 'visitedThrice':
      return visitCount <= 3
    default:
      return false
  }
}

// 修改获取提示信息的函数
const getDepositMessage = (depositSettings, visitCount, isLoggedIn) => {
  // 如果是所有人都需要定金
  if (depositSettings?.depositCondition === 'all') {
    return '此服務需要支付定金'
  }

  if (!isLoggedIn) {
    switch (depositSettings.depositCondition) {
      case 'newCustomer':
        return '新客戶需要支付定金'
      case 'visitedOnce':
        return '訪問次數2次以內需要支付定金'
      case 'visitedTwice':
        return '訪問次數3次以內需要支付定金'
      case 'visitedThrice':
        return '訪問次數4次以內需要支付定金'
      default:
        return '此服務可能需要支付定金'
    }
  }

  // 登入用戶的提示信息
  return `您的訪問次數為${
    visitCount === 0 ? '首次' : `${visitCount}次`
  }，需要支付定金`
}

const ServiceSelection = styled.div`
  margin: 20px 0;
  border-top: 1px solid #eee;
  scroll-margin-top: 20px;
`

const ServiceCategories = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  overflow-x: auto;
  padding-bottom: 5px;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d5728a;
    border-radius: 4px;
  }
`

const CategoryTab = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  background: ${(props) => (props.active ? '#d5728a' : '#f8e1e7')};
  color: ${(props) => (props.active ? 'white' : '#d5728a')};
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${(props) => (props.active ? '#c4617a' : '#f0d0d8')};
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 13px;
  }
`

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 15px;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`

const ServiceCard = styled.div`
  border: 1px solid ${(props) => (props.selected ? '#d5728a' : '#eee')};
  border-radius: 12px;
  padding: 15px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#fff9fa' : 'white')};
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    box-shadow: 0 2px 8px rgba(213, 114, 138, 0.15);
    transform: translateY(-2px);
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.6;
    cursor: not-allowed;
    &:hover {
      transform: none;
      box-shadow: none;
    }
  `}
`

const ServiceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`

const ServiceName = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #333;
`

const ServicePrice = styled.div`
  color: #d5728a;
  font-weight: 600;
  font-size: 16px;
`

const ServiceDetails = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
  text-align: left;
`

const ServiceCategory = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  background: #f8e1e7;
  color: #d5728a;
`

const BookingStepOne = ({
  business,
  product,
  setSelectedStylist,
  nextStep,
  depositSettings,
  additionalServices = [],
  setAdditionalServices
}) => {
  const [stylists, setStylists] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [visitCount, setVisitCount] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { user } = useAuth() // 獲取用戶登入狀態
  const [selectedStylistId, setSelectedStylistId] = useState(null)
  const serviceSelectionRef = useRef(null)
  const [selectedCategory, setSelectedCategory] = useState('全部')
  const [additionalServicesData, setAdditionalServicesData] = useState([])

  // 記憶化過濾後的設計師列表
  const availableStylists = useMemo(() => {
    if (!product?.stylists?.length || !business?.stylists?.length) return []
    return business.stylists.filter((stylist) =>
      product.stylists.includes(stylist._id)
    )
  }, [business?.stylists, product?.stylists])

  useEffect(() => {
    setStylists(availableStylists)
  }, [availableStylists])

  // 添加调试日志
  useEffect(() => {
    console.log('Current depositSettings:', depositSettings)
  }, [depositSettings])

  useEffect(() => {
    const fetchVisitCount = async () => {
      console.log('Current depositSettings:', depositSettings)

      // 如果定金设置启用且条件为 "all"，直接显示 Modal
      if (
        depositSettings?.isEnabled &&
        depositSettings.depositCondition === 'all'
      ) {
        console.log('Showing modal for all customers')
        setShowModal(true)
        setIsLoading(false)
        return
      }

      // 未登入用户的处理
      if (!user) {
        if (depositSettings?.isEnabled) {
          setShowModal(true)
        }
        setIsLoading(false)
        return
      }

      try {
        setIsLoading(true)
        const response = await api.get(
          `/api/client/bookings/visit-count/${business._id}`
        )
        const currentVisitCount = response.data.visitCount
        setVisitCount(currentVisitCount)

        // 根据访问次数判断是否显示 Modal
        if (shouldShowDeposit(depositSettings, currentVisitCount)) {
          setShowModal(true)
        }
      } catch (error) {
        console.error('獲取訪問次數失敗:', error)
        message.error('獲取訪問記錄失敗')
      } finally {
        setIsLoading(false)
      }
    }

    // 只要有 depositSettings 就执行检查
    if (depositSettings) {
      fetchVisitCount()
    }
  }, [depositSettings, user])

  const parseTags = (tags) => {
    if (Array.isArray(tags)) {
      return tags
    }
    return []
  }

  const filteredStylists = [
    // DEFAULT_STYLIST, // 暫時註解掉不指定設計師選項
    ...stylists
  ].filter((stylist) =>
    stylist.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  const handleGoBack = () => {
    navigate(`/store/${business.username}`)
  }

  // 修改服務選擇的處理函數
  const handleServiceSelection = useCallback(
    (service) => {
      if (!setAdditionalServices) return

      setAdditionalServices((prev) => {
        const prevServices = Array.isArray(prev) ? prev : []
        const serviceExists = prevServices.some((s) => s._id === service._id)

        if (serviceExists) {
          return prevServices.filter((s) => s._id !== service._id)
        }
        // 保存完整的服务对象，而不仅仅是 ID
        return [
          ...prevServices,
          {
            _id: service._id,
            name: service.name,
            price: service.price,
            duration: service.duration,
            orderIndex: service.orderIndex || 0
          }
        ]
      })
    },
    [setAdditionalServices]
  )

  // 修改选择设计师的处理函数
  const handleStylistSelect = useCallback(
    (stylistId) => {
      if (selectedStylistId === stylistId) return

      setSelectedStylistId(stylistId)
      setSelectedStylist(stylistId)

      // 如果没有额外服务，直接进入下一步
      if (!additionalServicesData.length) {
        nextStep()
        return
      }

      // 如果有额外服务，滚动到额外服务区域
      setTimeout(() => {
        serviceSelectionRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }, 100)
    },
    [
      selectedStylistId,
      setSelectedStylist,
      nextStep,
      additionalServicesData.length
    ]
  )

  useEffect(() => {
    const fetchAdditionalServices = async () => {
      try {
        const response = await api.get(
          `/api/business_C/${business._id}/${product._id}/additional-services`
        )
        setAdditionalServicesData(response.data.data.additionalServices)
      } catch (error) {
        console.error('獲取額外服務失敗:', error)
        message.error('獲取額外服務失敗')
      }
    }

    if (business?._id && product?._id) {
      fetchAdditionalServices()
    }
  }, [business?._id, product?._id])

  // Modal 组件
  const renderDepositModal = () => {
    if (!showModal) return null

    return (
      <ModalOverlay>
        <ModalContent>
          <ModalTitle>預約注意事項</ModalTitle>
          <ModalText>請注意！</ModalText>
          <ModalText>
            {getDepositMessage(depositSettings, visitCount, !!user)}
          </ModalText>
          <ModalText>
            預約定金請{depositSettings.autoCancelHours}小時內付款
          </ModalText>
          <ModalText>目前接受銀行轉帳，收到款項後店家會確認預約。</ModalText>
          {!user && (
            <ModalText style={{ color: '#ff4d4f' }}>
              請先登入以查看您的實際定金要求
            </ModalText>
          )}
          <ConfirmButton onClick={() => setShowModal(false)}>
            確認
          </ConfirmButton>
        </ModalContent>
      </ModalOverlay>
    )
  }

  return (
    <LeftPane>
      <BackButton onClick={handleGoBack}>
        <ArrowLeft size={20} style={{ marginRight: '5px' }} />
        返回店家
      </BackButton>
      <SectionTitle>選擇員工</SectionTitle>
      <SearchWrapper>
        <SearchIcon size={20} />
        <SearchInput
          placeholder='搜尋員工'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchWrapper>
      <EmployeeContainer>
        {filteredStylists.length > 0 ? (
          filteredStylists.map((stylist) => {
            const tags = parseTags(stylist.tags)
            const displayTags = getDisplayTags(tags)
            return (
              <EmployeeCard
                key={stylist._id}
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.98 }}
              >
                <EmployeeAvatar
                  src={`${PHOTO_URL}${stylist.avatar}` || DEFAULT_AVATAR}
                  alt={stylist?.name}
                  onClick={() => handleStylistSelect(stylist._id)}
                />
                <StylistInfo>
                  <StylistName>{stylist.name}</StylistName>
                  {stylist._id === null ? (
                    <StylistDescription>接受店家現場安排</StylistDescription>
                  ) : (
                    <>
                      <StylistTags>
                        {displayTags.map((tag, index) => (
                          <StylistTag key={index}>{tag}</StylistTag>
                        ))}
                      </StylistTags>
                      <StylistDescription>
                        {stylist.description || '請於預約時填寫需求'}
                      </StylistDescription>
                    </>
                  )}
                  <SelectButton
                    onClick={() => handleStylistSelect(stylist._id)}
                  >
                    選擇
                  </SelectButton>
                </StylistInfo>
              </EmployeeCard>
            )
          })
        ) : (
          <EmptyState>沒有找到匹配的員工</EmptyState>
        )}
      </EmployeeContainer>
      {selectedStylistId && additionalServicesData.length > 0 && (
        <>
          <div ref={serviceSelectionRef}>
            <ServiceSelection>
              <SectionTitle>選擇額外服務（選填）</SectionTitle>
              <ServiceCategories>
                <CategoryTab
                  active={selectedCategory === '全部'}
                  onClick={() => setSelectedCategory('全部')}
                >
                  全部
                </CategoryTab>
                {['加強', '升級', '搭配', '其他'].map((category) => (
                  <CategoryTab
                    key={category}
                    active={selectedCategory === category}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category}
                  </CategoryTab>
                ))}
              </ServiceCategories>

              <ServiceGrid>
                {additionalServicesData
                  .filter(
                    (service) =>
                      selectedCategory === '全部' ||
                      service.category === selectedCategory
                  )
                  .map((service) => {
                    const isSelected = additionalServices.some(
                      (selected) => selected._id === service._id
                    )
                    const isDisabled =
                      service.restrictions?.incompatibleWith?.some((id) =>
                        additionalServices.some(
                          (selected) => selected._id === id
                        )
                      )

                    return (
                      <ServiceCard
                        key={service._id}
                        selected={isSelected}
                        disabled={isDisabled}
                        onClick={() =>
                          !isDisabled && handleServiceSelection(service)
                        }
                      >
                        <ServiceCategory>{service.category}</ServiceCategory>
                        <ServiceHeader>
                          <ServiceName>{service.name}</ServiceName>
                        </ServiceHeader>
                        <ServiceDetails>
                          <ServicePrice>${service.price}</ServicePrice>
                          <div>{service.description}</div>
                          <div>時長：{service.duration} 分鐘</div>
                        </ServiceDetails>
                      </ServiceCard>
                    )
                  })}
              </ServiceGrid>
            </ServiceSelection>
          </div>
          <SelectButton
            onClick={nextStep}
            style={{
              width: '100%',
              marginTop: '20px',
              padding: '10px',
              fontSize: '16px'
            }}
          >
            下一步
          </SelectButton>
        </>
      )}
      {renderDepositModal()}
    </LeftPane>
  )
}

export default BookingStepOne
