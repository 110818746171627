import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import { HeartOutlined, HeartFilled } from '@ant-design/icons'
import Loading from '../utils/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getTagColor,
  tags,
  categories,
  translateCategoryToChinese
} from '../utils/utils'
import api from '../utils/api'
import { useAuth } from '../hooks/AuthContext'
import { message } from 'antd'
import { Toast } from 'antd-mobile'
import { Pagination, Empty, Divider } from 'antd'
import { Popup } from 'antd-mobile'
import { EnvironmentOutlined, DownOutlined } from '@ant-design/icons'
import cityData, {
  convertEngToFullAddress,
  cityNameMapping,
  districtNameMapping,
  convertToEngName
} from '../utils/cityData'
import { Helmet } from 'react-helmet-async'

const Container = styled.div`
  display: flex;
  font-family: Arial, sans-serif;
  @media (max-width: 820px) {
    flex-direction: column;
  }
`

const FilterContent = styled.div`
  padding: 0px 20px 20px 20px;
  flex: 1;
  @media (max-width: 820px) {
    padding: 0px 20px 0px 20px;
  }
`

const MainContent = styled.div`
  padding: 10px 20px 20px 20px;
  flex: 3;
  @media (max-width: 820px) {
    padding: 0px 20px 20px 20px;
  }
`

const CategoriesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @media (max-width: 820px) {
    margin: 10px 0;
  }
`

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.$active ? '#D5728A' : '#eee')};
  }

  @media (max-width: 820px) {
    padding: 7px;
  }
`

const CategoryIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 5px;
  @media (max-width: 820px) {
    width: 20px;
    height: 20px;
  }
`

const CategoryName = styled.div`
  font-size: 14px;
  @media (max-width: 820px) {
    font-size: 14px;
  }
`

const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  min-height: 300px;

  @media (max-width: 820px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`

const EmptyContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`

const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #eee;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
`

const ServiceImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  background-color: #f6f6f6;
`

const ServiceDetails = styled.div`
  padding: 10px;
`

const ServiceName = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
`

const ServicePrice = styled.div`
  padding-top: 10px;
  color: #d5728a;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
`

const Tags = styled.div`
  display: flex;
  overflow-x: auto;
  margin-bottom: 20px;
`

const TagsContainer = styled.div`
  cursor: pointer;
  border-radius: 20px;
  padding: 5px 15px;
  margin-right: 10px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#EEE')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  white-space: nowrap;
`

const CardTagContainer = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
`

const CardTag = styled.div`
  background-color: ${(props) => props.color};
  padding: 3px 6px;
  border-radius: 5px;
  margin-right: 5px;
`

const CardTagText = styled.div`
  font-size: 12px;
  color: #896868;
`
const Box = styled.div`
  display: flex;
  justify-content: space-between;
`
const ReserveButton = styled.div`
  padding: 5px;
  border-radius: 5px;
  background-color: #d5728a;
  cursor: pointer;
  &:hover {
    background-color: #bf5170;
  }
`

const ReserveButtonText = styled.div`
  padding: 0px 10px;
  font-size: 16px;
  color: #fff;
`
const FavoriteButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px 7px;
  cursor: pointer;
  font-size: 18px;
  color: ${(props) => (props.$favorited ? '#d5728a' : '#aaa')};
`

const RegionFilter = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 820px) {
    margin: 10px 0;
  }
`

const RegionTitle = styled.h3`
  width: 100%;
  margin-bottom: 10px;
`

const ServiceTitle = styled.h3`
  width: 100%;
`

const RegionAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #c56b81;
    color: #fff;
  }
  @media (max-width: 820px) {
    padding: 7px;
    margin-bottom: 10px;
  }
`

const RegionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`

const RegionItem = styled.div`
  padding: 5px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  border-radius: 10px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${(props) => (props.$active ? '#D5728A' : '#eee')};
  }

  @media (max-width: 820px) {
    grid-column: span 1;
  }
`

const MobileRegionButton = styled.button`
  display: none;
  width: 100%;
  padding: 12px 15px;
  margin: 20px 0 10px 0;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  font-size: 16px;

  &:active {
    background: #f5f5f5;
  }

  @media (max-width: 820px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease;
  }
`

const RegionButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
`

const RegionIcon = styled(EnvironmentOutlined)`
  color: #d5728a;
  font-size: 18px;
`

const RegionText = styled.span`
  color: ${(props) => (props.$hasSelection ? '#333' : '#999')};
`

const DownIcon = styled(DownOutlined)`
  color: #999;
  font-size: 12px;
  margin-left: auto;
`
const PopupContent = styled.div`
  padding: 20px;
  padding-bottom: 80px; // 添加底部 padding
  flex: 1;
  overflow-y: auto;
  position: relative;
`

const PopupTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`

const PopupGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`
const BackButtonContainer = styled.div`
  padding: 16px;
  background: white;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.06);
`

const BackButton = styled.button`
  width: 100%;
  background-color: #d5728a;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #c4617a;
  }

  &:active {
    transform: scale(0.98);
  }
`

const DesktopRegionFilter = styled(RegionFilter)`
  @media (max-width: 820px) {
    display: none;
  }
`
const DistrictsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
`

const DistrictItem = styled(RegionItem)`
  font-size: 14px;
  padding: 5px;
`

const DistrictFilter = styled(DesktopRegionFilter)`
  margin-top: 20px;
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 820px) {
    .ant-pagination {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }

    .ant-pagination-total-text {
      flex-basis: 100%;
      text-align: center;
      margin-bottom: 15px;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      margin: 0 4px;
      align-items: center;
    }

    .ant-pagination-options {
      margin-top: 10px;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
    }
  }
`

const LoadingMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`
const DesktopCategoryFilter = styled.div`
  @media (max-width: 820px) {
    display: none;
  }
`

const TitleH1 = styled.h1`
  position: relative;
  font-size: 20px;
  color: #666;
  padding: 18px 25px;
  font-weight: 500;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 12px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 40%;
    width: 4px;
    background: linear-gradient(to bottom, #d5728a, #e598a8);
    border-radius: 2px;
  }

  @media (max-width: 820px) {
    font-size: 1.2rem;
    padding: 14px 20px;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    align-items: flex-start;
  }
`

const LocationText = styled.span`
  margin-right: 8px;
`

const ServiceText = styled.span`
  color: #d5728a;
`

const ServicesPage = () => {
  const [services, setServices] = useState([])
  const [filterTag, setFilterTag] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState('nail')
  const [loading, setLoading] = useState(false)
  const [favorites, setFavorites] = useState([])
  const navigate = useNavigate()
  const { user } = useAuth()
  const [currentPage, setCurrentPage] = useState(1)
  const [totalServices, setTotalServices] = useState(0)
  const [selectedRegion, setSelectedRegion] = useState('all')
  const [showPopup, setShowPopup] = useState(false)
  const [areaLoading, setAreaLoading] = useState(false)
  const [selectedCity, setSelectedCity] = useState(null)
  const [districts, setDistricts] = useState([])
  const [selectedDistrict, setSelectedDistrict] = useState(null)
  const {
    category = 'nail',
    city = 'all-city',
    district = 'all-district',
    page = 1
  } = useParams()

  useEffect(() => {
    setCurrentPage(parseInt(page))
  }, [page])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        let response

        const addressString =
          district === 'all-district' ? city : `${city}-${district}`

        const chineseRegion = convertEngToFullAddress(addressString)

        if (chineseRegion && chineseRegion !== 'all') {
          setSelectedRegion(chineseRegion)
          const cityInfo = cityData.districts.find((d) =>
            chineseRegion.startsWith(d.name)
          )
          if (cityInfo) {
            setSelectedCity(cityInfo.name)
            const districtName = chineseRegion.slice(cityInfo.name.length)
            if (districtName) {
              setSelectedDistrict(districtName)
              setDistricts(cityInfo.areas)
            }
          }

          response = await api.get(`/api/search/services-by-region`, {
            params: {
              page: page,
              serviceType: category !== 'all' ? category : undefined,
              region: chineseRegion
            }
          })
        } else {
          response = await api.get(`/api/business_C/random-services`, {
            params: {
              page: page,
              serviceType: category !== 'all' ? category : undefined
            }
          })
        }

        setServices(response.data.services)
        setTotalServices(response.data.totalResults || 0)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    // 添加一個防抖函數
    const debounceTimer = setTimeout(() => {
      fetchData()
    }, 300)

    return () => clearTimeout(debounceTimer)
  }, [category, city, district, page])

  const handleCategorySelect = (categoryType) => {
    setSelectedCategory(categoryType)
    const categoryParam = categoryType === 'all' ? 'all' : categoryType
    navigate(`/services/${categoryParam}/${city}/${district}/${currentPage}`)
  }

  const handleCitySelect = async (selectedCity) => {
    setAreaLoading(true)
    setSelectedCity(selectedCity)
    setSelectedDistrict(null)

    try {
      if (selectedCity === 'all') {
        setDistricts([])
        setSelectedRegion('all')
        navigate(`/services/${category}/all-city/all-district/1`)
      } else {
        // 找到對應的城市資料
        const cityInfo = cityData.districts.find((d) => d.name === selectedCity)
        if (cityInfo && cityInfo.areas) {
          setDistricts(cityInfo.areas)
        }

        // 設置選中的區域
        setSelectedRegion(selectedCity)

        // 使用 cityNameMapping 進行轉換
        const engCity = cityNameMapping[selectedCity]

        // 加入檢查和日誌
        console.log('Selected City:', selectedCity)
        console.log('Mapped English City:', engCity)

        if (!engCity) {
          console.error('No English mapping found for city:', selectedCity)
          return
        }

        navigate(`/services/${category}/${engCity}/all-district/1`)
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setAreaLoading(false)
      setShowPopup(false)
    }
  }

  const handleDistrictSelect = (selectedDistrict) => {
    setSelectedDistrict(selectedDistrict)
    const fullRegion = `${selectedCity}${selectedDistrict}`
    setSelectedRegion(fullRegion)

    const engCity = cityNameMapping[selectedCity] || 'all-city'
    const engDistrict = districtNameMapping[selectedDistrict] || 'all-district'

    navigate(`/services/${category}/${engCity}/${engDistrict}/1`, {
      replace: true
    })
    setShowPopup(false)
  }

  const handlePageChange = (newPage) => {
    const engCity = cityNameMapping[selectedCity] || 'all-city'
    const engDistrict = selectedDistrict
      ? districtNameMapping[selectedDistrict]
      : 'all-district'

    navigate(`/services/${category}/${engCity}/${engDistrict}/${newPage}`, {
      replace: true
    })
  }

  const handleClick = (username, productId) => {
    navigate(`/booking/${username}/${productId}`)
  }

  const filteredServices = filterTag
    ? services.filter((service) => service.product.tags.includes(filterTag))
    : services

  const toggleFavorite = async (businessId, productId) => {
    if (!user) {
      Toast.show({
        content: '請先登錄以收藏服務',
        position: 'top'
      })
      return
    }

    try {
      if (favorites.includes(productId)) {
        await api.delete(
          `${API_BASE_URL}/api/user/${user.userId}/favorite-services/${businessId}/${productId}`
        )
        setFavorites(favorites.filter((id) => id !== productId))
        message.success('已取消收藏')
      } else {
        await api.post(
          `${API_BASE_URL}/api/user/${user.userId}/favorite-services/${businessId}/${productId}`
        )
        setFavorites([...favorites, productId])
        message.success('已添加到收藏')
      }
    } catch (error) {
      console.error('收藏操作失敗:', error)
      message.error('收藏操作失敗，請稍後重試。')
    }
  }

  const getDisplayRegion = () => {
    if (selectedRegion === 'all' || !selectedRegion) {
      return '台灣'
    }
    return selectedRegion
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Container>
      <Helmet>
        <title>
          探索
          {`${selectedRegion === 'all' ? '台灣' : selectedRegion}${
            category === 'all' ? '美容' : translateCategoryToChinese(category)
          }線上預約服務 - 美感｜BeauteFeel`}
        </title>
        <meta
          name='description'
          content={`在美感 BeauteFeel，一鍵查找並預約你想要的服務，輕鬆比較價格與評價，找到最適合您的美容店家，立即預約享優惠！
`}
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Review',
            itemReviewed: {
              '@type': 'Service',
              name: `${selectedRegion === 'all' ? '台灣' : selectedRegion}${
                category === 'all'
                  ? '美容、美甲、美髮'
                  : translateCategoryToChinese(category)
              }線上預約服務`,
              provider: {
                '@type': 'Organization',
                name: '美感 BeauteFeel'
              },
              areaServed: {
                '@type': 'Place',
                address: {
                  '@type': 'PostalAddress',
                  addressRegion:
                    selectedRegion === 'all' ? '台灣' : selectedRegion,
                  addressCountry: '台灣'
                }
              }
            },
            reviewRating: {
              '@type': 'Rating',
              ratingValue: '4.5',
              bestRating: '5',
              worstRating: '1'
            },
            author: {
              '@type': 'Organization',
              name: '美感 BeauteFeel'
            },
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: '4.5',
              reviewCount: totalServices,
              ratingCount: totalServices,
              bestRating: '5',
              worstRating: '1'
            }
          })}
        </script>
      </Helmet>
      <FilterContent>
        <DesktopRegionFilter className='desktop-only'>
          <Divider style={{ margin: '0' }}>
            <RegionTitle>縣市篩選</RegionTitle>
          </Divider>
          <RegionAll
            $active={selectedRegion === 'all' || selectedRegion === 'all-city'}
            onClick={() => handleCitySelect('all')}
          >
            全部地區
          </RegionAll>
          <RegionGrid>
            {cityData.counties.map((city) => (
              <RegionItem
                key={city}
                $active={selectedCity === city}
                onClick={() => handleCitySelect(city)}
              >
                {city}
              </RegionItem>
            ))}
          </RegionGrid>
        </DesktopRegionFilter>

        {selectedCity && selectedCity !== 'all-city' && (
          <DistrictFilter className='desktop-only'>
            <RegionTitle>區域篩選</RegionTitle>
            <RegionGrid>
              {districts.map((district) => (
                <RegionItem
                  key={district}
                  $active={selectedDistrict === district}
                  onClick={() => handleDistrictSelect(district)}
                >
                  {district}
                </RegionItem>
              ))}
            </RegionGrid>
          </DistrictFilter>
        )}

        <MobileRegionButton onClick={() => setShowPopup(true)}>
          <RegionButtonContent>
            <RegionIcon />
            <RegionText $hasSelection={selectedRegion !== 'all'}>
              {getDisplayRegion()}
            </RegionText>
          </RegionButtonContent>
          <DownIcon />
        </MobileRegionButton>

        <DesktopCategoryFilter className='desktop-only'>
          <Divider style={{ margin: '0' }}>
            <ServiceTitle>服務類型</ServiceTitle>
          </Divider>
        </DesktopCategoryFilter>
        <CategoriesContainer>
          {categories.map((cat, index) => (
            <CategoryItem
              key={index}
              $active={selectedCategory === cat.type}
              onClick={() => handleCategorySelect(cat.type)}
            >
              <CategoryIcon src={`${cat.icon}`} alt={cat.name} />
              <CategoryName>{cat.name}</CategoryName>
            </CategoryItem>
          ))}
        </CategoriesContainer>
      </FilterContent>

      <MainContent>
        <TitleH1>
          <LocationText>{getDisplayRegion()}</LocationText>
          <ServiceText>
            {category === 'all'
              ? '所有美容、美甲、美髮等'
              : translateCategoryToChinese(category)}
            服務項目
            {totalServices > 0 && `（${totalServices}）`}
          </ServiceText>
        </TitleH1>

        <Tags>
          <TagsContainer
            onClick={() => setFilterTag(null)}
            $active={filterTag === null}
          >
            全部
          </TagsContainer>
          {tags.map((tag, index) => (
            <TagsContainer
              key={index}
              onClick={() => setFilterTag(tag.label)}
              $active={filterTag === tag.label}
            >
              {tag.label}
            </TagsContainer>
          ))}
        </Tags>

        <ServicesContainer>
          {filteredServices.length > 0 ? (
            filteredServices.map((service, index) => (
              <ServiceCard key={index}>
                <ServiceImage
                  src={`${PHOTO_URL}${service.product.image}`}
                  alt={service.product.name}
                />
                <FavoriteButton
                  $favorited={service.isFavorited}
                  onClick={() =>
                    toggleFavorite(service.businessId, service.product._id)
                  }
                >
                  {service.isFavorited ? <HeartFilled /> : <HeartOutlined />}
                </FavoriteButton>
                <ServiceDetails>
                  {service.product.tags && (
                    <CardTagContainer>
                      {service.product.tags.slice(0, 2).map((tag, index) => (
                        <CardTag key={index} color={getTagColor(tag)}>
                          <CardTagText>{tag}</CardTagText>
                        </CardTag>
                      ))}
                    </CardTagContainer>
                  )}
                  <ServiceName>{service.product.name}</ServiceName>
                  <Box style={{ display: 'flex' }}>
                    <ServicePrice>$ {service.product.price}</ServicePrice>
                    <ReserveButton
                      onClick={() =>
                        handleClick(
                          service.businessUsername,
                          service.product._id
                        )
                      }
                    >
                      <ReserveButtonText>預約</ReserveButtonText>
                    </ReserveButton>
                  </Box>
                </ServiceDetails>
              </ServiceCard>
            ))
          ) : (
            <EmptyContainer>
              <Empty
                description='沒有該地區或類型的服務，請嘗試其他地區或類型'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </EmptyContainer>
          )}
        </ServicesContainer>

        <PaginationContainer>
          <Pagination
            current={currentPage}
            total={totalServices}
            pageSize={30}
            onChange={handlePageChange}
            showSizeChanger={false}
            showTotal={(total, range) => `共 ${total} 項服務`}
          />
        </PaginationContainer>
      </MainContent>

      <Popup
        visible={showPopup}
        onMaskClick={() => setShowPopup(false)}
        position='bottom'
        bodyStyle={{ height: '80vh', display: 'flex', flexDirection: 'column' }}
      >
        <PopupContent>
          <PopupTitle>按地區搜尋</PopupTitle>
          <RegionAll
            $active={selectedRegion === 'all'}
            onClick={() => handleCitySelect('all')}
          >
            全部地區
          </RegionAll>
          <PopupGrid>
            {cityData.counties.map((city) => (
              <RegionItem
                key={city}
                $active={selectedRegion === city}
                onClick={() => handleCitySelect(city)}
              >
                {city}
              </RegionItem>
            ))}
          </PopupGrid>

          {/* 显示区域选择 */}
          {selectedCity && districts.length > 0 && (
            <DistrictsGrid>
              {districts.map((district) => (
                <DistrictItem
                  key={district}
                  $active={selectedDistrict === district}
                  onClick={() => handleDistrictSelect(district)}
                >
                  {district}
                </DistrictItem>
              ))}
            </DistrictsGrid>
          )}
        </PopupContent>
        <BackButtonContainer>
          <BackButton onClick={() => setShowPopup(false)}>返回</BackButton>
        </BackButtonContainer>
      </Popup>

      {areaLoading && (
        <LoadingMask>
          <Loading />
        </LoadingMask>
      )}
    </Container>
  )
}

export default ServicesPage
