import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import api from '../utils/api'
import { PHOTO_URL } from '../utils/apiutils'
import { Popup } from 'antd-mobile'
import Loading from '../utils/Loading'
import { categories, translateCategoryToChinese } from '../utils/utils'
import { useNavigate, useParams } from 'react-router-dom'
import { Tag, Input, Pagination, Empty, Divider } from 'antd'
import { Helmet } from 'react-helmet-async'
import {
  SearchOutlined,
  EnvironmentOutlined,
  DownOutlined
} from '@ant-design/icons'
import cityData from '../utils/cityData'
import {
  convertToEngName,
  convertToChineseName,
  districtNameMapping,
  cityNameMapping
} from '../utils/cityData'

const Container = styled.div`
  display: flex;
  font-family: Arial, sans-serif;

  @media (max-width: 1155px) {
    flex-direction: column;
  }
`

const FilterContent = styled.div`
  padding: 20px;
  flex: 1;

  @media (max-width: 1155px) {
    padding: 10px;
  }
`

const MainContent = styled.div`
  padding: 10px 20px 20px 20px;
`

const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  @media (max-width: 1155px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 10px 0 20px 0;
  }
`

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.$active ? '#D5728A' : '#eee')};
  }
  @media (max-width: 1155px) {
    justify-content: center;
    padding: 5px;
    margin: 0;
  }
`

const CategoryIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  @media (max-width: 1155px) {
    width: 20px;
    height: 20px;
  }
`

const CategoryName = styled.div`
  font-size: 16px;
  @media (max-width: 1155px) {
    font-size: 14px;
  }
`

const PhotoWall = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  /* padding: 20px 20px 20px 0px; */

  @media (max-width: 1155px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
    padding: 4px;
  }
`

const PhotoItem = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 1155px) {
    border-radius: 0;
    box-shadow: none;
  }
`

const PhotoImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
`

const PhotoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 15px;
  opacity: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1155px) {
    opacity: 1;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 70%
    );

    :hover {
      opacity: 0;
    }
  }
`

const StylistName = styled.h3`
  color: white;
  margin: 0;
  font-size: 18px;
  font-weight: 600;

  @media (max-width: 1155px) {
    font-size: 14px;
  }
`

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 1155px) {
    margin: 10px 10px 0 10px;
    flex-direction: row;
  }
`

const SearchInput = styled(Input)`
  border-radius: 12px;
  padding: 8px 15px;

  .ant-input {
    font-size: 14px;
  }

  .ant-input-prefix {
    margin-right: 8px;
    color: #d5728a;
  }
`

const SearchButton = styled.button`
  background-color: #d5728a;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccc' : '#c4617a')};
    transform: ${(props) => (props.disabled ? 'none' : 'translateY(-2px)')};
    box-shadow: ${(props) =>
      props.disabled ? 'none' : '0 4px 8px rgba(0, 0, 0, 0.15)'};
  }
`

const DesktopRegionFilter = styled.div`
  margin-bottom: 20px;
  @media (max-width: 1155px) {
    display: none;
  }
`

const RegionTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`

const RegionAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #c56b81;
    color: #fff;
  }
  @media (max-width: 1155px) {
    padding: 7px;
  }
`

const RegionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`

const RegionItem = styled.div`
  padding: 5px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  border-radius: 10px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${(props) => (props.$active ? '#D5728A' : '#eee')};
  }

  @media (max-width: 1155px) {
    grid-column: span 1;
  }
`

const DistrictFilter = styled.div`
  margin-bottom: 20px;
  @media (max-width: 1155px) {
    display: none;
  }
`

const EmptyContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 1155px) {
    .ant-pagination {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      a {
        padding: 0 11px;
      }
    }

    .ant-pagination-total-text {
      flex-basis: 100%;
      text-align: center;
      margin-bottom: 15px;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      margin: 0 4px;
      display: inline-flex;
      align-items: center;
    }

    .ant-pagination-options {
      margin-top: 10px;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
    }
  }
`

const DesignerInfo = styled.div`
  padding: 10px;
  color: white;
  text-align: left;
`

const BusinessInfo = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 4px;
`

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 6px;

  @media (max-width: 1155px) {
    /* 在手機版隱藏除了最後一個以外的所有標籤 */
    & > *:not(:last-child) {
      display: none;
    }
  }
`

const SkillTag = styled(Tag)`
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 10px;
  margin: 0;
  background-color: rgba(213, 114, 138, 0.8);
  border: none;
  color: white;
`

const ExperienceTag = styled(Tag)`
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 10px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
`
const MobileRegionButton = styled.button`
  display: none;
  padding: 12px 15px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  margin: 0px 20px 10px 20px;
  font-size: 16px;

  &:active {
    background: #f5f5f5;
  }

  @media (max-width: 1155px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease;
  }
`

const RegionButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
`

const RegionIcon = styled(EnvironmentOutlined)`
  color: #d5728a;
  font-size: 18px;
`

const RegionText = styled.span`
  color: ${(props) => (props.$hasSelection ? '#333' : '#999')};
`
const DownIcon = styled(DownOutlined)`
  color: #999;
  font-size: 12px;
  margin-left: auto;
`

const PopupContent = styled.div`
  padding: 20px;
  padding-bottom: 80px; // 添加底部 padding
  flex: 1;
  overflow-y: auto;
  position: relative;
`

const PopupTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`

const PopupGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`
const DistrictsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
`

const DistrictItem = styled(RegionItem)`
  font-size: 14px;
  padding: 5px;
`

const BackButtonContainer = styled.div`
  padding: 16px;
  background: white;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.06);
`

const BackButton = styled.button`
  width: 100%;
  background-color: #d5728a;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #c4617a;
  }

  &:active {
    transform: scale(0.98);
  }
`

// 定義品牌色系
const colors = {
  primary: '#d5728a',
  primaryLight: '#e094a7',
  primaryLighter: '#fdf0f3',
  primaryDark: '#b85571',
  contrast: '#000',
  contrastLight: '#f39c12',
  gray: '#626262',
  grayLight: '#8c8c8c',
  white: '#ffffff'
}

const TitleH1 = styled.h1`
  position: relative;
  font-size: 20px;
  color: ${colors.gray};
  margin: 10px 0;
  padding: 18px 20px;
  font-weight: 500;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 12px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 40%;
    width: 4px;
    background: linear-gradient(
      to bottom,
      ${colors.primary},
      ${colors.primaryLight}
    );
    border-radius: 2px;
  }

  @media (max-width: 1155px) {
    font-size: 1.2rem;
    padding: 14px 20px;
    margin: 20px 10px;

    &::after {
      font-size: 0.9rem;
      padding: 5px 14px;
    }
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    align-items: flex-start;
  }
`

const LocationText = styled.span`
  color: ${colors.primary};
  font-weight: 600;
  position: relative;
  display: inline-block;
  padding-right: 5px;
`

const ServiceText = styled.span`
  color: ${colors.gray};
  font-weight: 600;
  position: relative;
`

// 添加搜索結果提示的樣式
const SearchInfo = styled.div`
  color: ${colors.grayLight};
  font-size: 0.9rem;
  margin: -20px 25px 20px;
  padding: 8px 16px;
  background: ${colors.primaryLighter};
  border-radius: 8px;
  display: inline-block;

  strong {
    color: ${colors.primary};
    font-weight: 600;
  }
`

const StylistsPage = () => {
  const [stylists, setStylists] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [loading, setLoading] = useState(true)
  const [keyword, setKeyword] = useState('')
  const [selectedCity, setSelectedCity] = useState('all')
  const [selectedDistrict, setSelectedDistrict] = useState(null)
  const [districts, setDistricts] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const navigate = useNavigate()
  const {
    category = 'all',
    city = 'all-city',
    district = 'all-district',
    page = '1'
  } = useParams()
  const [searchText, setSearchText] = useState('')
  const [isSearching, setIsSearching] = useState(false)

  const fetchDesigners = async (options = {}) => {
    try {
      setLoading(true)
      const pageToUse = options.page !== undefined ? options.page : currentPage
      const params = {
        page: pageToUse,
        limit: 30
      }

      // 使用中文發送請求
      if (options.city && options.city !== 'all') {
        params.region = options.city
        if (options.district && options.district !== 'all-district') {
          params.district = convertToChineseName(options.district)
        }
      }

      if (options.category && options.category !== 'all') {
        params.skillType = options.category
      }

      if (options.searchText?.trim()) {
        params.keyword = options.searchText.trim()
      }

      console.log('Fetching with params:', params) // 添加日誌以便調試

      const response = await api.get('/api/search/designers', { params })
      setStylists(response.data.designers)
      setTotalResults(response.data.totalResults)
      setShowPopup(false)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const chineseCity = city === 'all-city' ? 'all' : convertToChineseName(city)
    const chineseDistrict =
      district === 'all-district' ? null : convertToChineseName(district)
    const pageNumber = parseInt(page)

    console.log('Initial page number:', pageNumber) // 添加日誌

    setSelectedCategory(category)
    setSelectedCity(chineseCity)
    setSelectedDistrict(chineseDistrict)
    setCurrentPage(pageNumber)

    // 設置區域列表
    if (chineseCity && chineseCity !== 'all') {
      const cityInfo = cityData.districts.find((d) => d.name === chineseCity)
      if (cityInfo) {
        setDistricts(cityInfo.areas)
      }
    }

    // 確保使用正確的頁碼
    fetchDesigners({
      category,
      city: chineseCity,
      district: chineseDistrict,
      page: pageNumber
    })
  }, [category, city, district, page])

  const updateURL = (newCategory, newCity, newDistrict, newPage) => {
    navigate(`/stylists/${newCategory}/${newCity}/${newDistrict}/${newPage}`)
  }

  const handleCitySelect = async (chineseCity) => {
    const engCity =
      chineseCity === 'all' ? 'all-city' : cityNameMapping[chineseCity]

    if (chineseCity === 'all') {
      setDistricts([])
      setSelectedDistrict(null)
    } else {
      const cityInfo = cityData.districts.find((d) => d.name === chineseCity)
      setDistricts(cityInfo ? cityInfo.areas : [])
    }

    setSelectedCity(chineseCity) // 保存中文城市名
    updateURL(selectedCategory, engCity, 'all-district', 1)

    // 使用中文發送 API 請求
    await fetchDesigners({
      category: selectedCategory,
      city: chineseCity,
      district: null,
      page: 1
    })
  }

  const handleDistrictSelect = async (chineseDistrict) => {
    const engCity =
      selectedCity === 'all' ? 'all-city' : cityNameMapping[selectedCity]
    const engDistrict = districtNameMapping[chineseDistrict]

    setSelectedDistrict(chineseDistrict) // 保存中文區域名
    updateURL(selectedCategory, engCity, engDistrict, 1)

    // 使用中文發送 API 請求
    await fetchDesigners({
      category: selectedCategory,
      city: selectedCity,
      district: chineseDistrict,
      page: 1
    })
  }

  const handlePageChange = (newPage) => {
    const engCity =
      selectedCity === 'all' ? 'all-city' : cityNameMapping[selectedCity]
    const engDistrict = selectedDistrict
      ? districtNameMapping[selectedDistrict]
      : 'all-district'

    setCurrentPage(newPage)
    updateURL(selectedCategory, engCity, engDistrict, newPage)

    // 使用中文發送 API 請求
    fetchDesigners({
      category: selectedCategory,
      city: selectedCity,
      district: selectedDistrict,
      page: newPage
    })
  }

  const clearSearch = async () => {
    setSearchText('')
    setKeyword('')
    setCurrentPage(1)

    await fetchDesigners({
      city: selectedCity,
      district: selectedDistrict,
      category: selectedCategory,
      searchText: '',
      page: 1
    })
  }

  const handleSearch = async () => {
    if (!searchText.trim()) {
      clearSearch()
      return
    }

    setIsSearching(true)
    setKeyword(searchText.trim())
    setCurrentPage(1)

    await fetchDesigners({
      city: selectedCity, // 使用中文城市名
      district: selectedDistrict, // 使用中文區域名
      category: selectedCategory,
      searchText: searchText.trim(),
      page: 1
    })
    setIsSearching(false)
  }

  const validStylists = stylists.filter(
    (designer) =>
      designer.name && designer.skillsType && designer.skillsType.length > 0
  )

  const getServiceTypeName = (serviceType) => {
    const category = categories.find((cat) => cat.type === serviceType)
    return category ? category.name : serviceType
  }

  const handleCategorySelect = async (categoryType) => {
    setSelectedCategory(categoryType)

    // 保持當前的城市和區域狀態，但使用英文更新 URL
    const engCity =
      selectedCity === 'all' ? 'all-city' : cityNameMapping[selectedCity]
    const engDistrict = selectedDistrict
      ? districtNameMapping[selectedDistrict]
      : 'all-district'

    updateURL(categoryType, engCity, engDistrict, 1)

    // 使用中文發送 API 請求
    await fetchDesigners({
      category: categoryType,
      city: selectedCity, // 使用中文城市名
      district: selectedDistrict, // 使用中文區域名
      page: 1
    })
  }

  const generateMetaDescription = () => {
    const categoryText =
      selectedCategory === 'all'
        ? '美容美髮'
        : translateCategoryToChinese(selectedCategory)
    const locationText =
      selectedCity === 'all'
        ? '全台'
        : selectedDistrict
        ? `${selectedCity}${selectedDistrict}`
        : selectedCity

    return `在${locationText}尋找專業的${categoryText}服務，線上預約美容、美甲、美髮服務 - BeauteFeel美感預約平台`
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Container>
      <Helmet>
        <title>
          {`${selectedCity === 'all' ? '台灣' : selectedCity}${
            selectedCategory === 'all'
              ? '美容美髮'
              : translateCategoryToChinese(selectedCategory)
          }設計師線上預約 - 美感 BeauteFeel`}
        </title>
        <meta name='description' content={generateMetaDescription()} />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Review',
            itemReviewed: {
              '@type': 'Service',
              name: `${selectedCity === 'all' ? '台灣' : selectedCity}${
                selectedCategory === 'all'
                  ? '美容、美甲、美髮'
                  : translateCategoryToChinese(selectedCategory) + '設計師'
              }預約服務`,
              provider: {
                '@type': 'Organization',
                name: '美感 BeauteFeel'
              },
              areaServed: {
                '@type': 'Place',
                address: {
                  '@type': 'PostalAddress',
                  addressRegion: selectedCity === 'all' ? '台灣' : selectedCity,
                  addressCountry: '台灣'
                }
              }
            },
            reviewRating: {
              '@type': 'Rating',
              ratingValue: '4.5',
              bestRating: '5',
              worstRating: '1'
            },
            author: {
              '@type': 'Organization',
              name: '美感 BeauteFeel'
            },
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: '4.5',
              reviewCount: totalResults,
              ratingCount: totalResults,
              bestRating: '5',
              worstRating: '1'
            }
          })}
        </script>
      </Helmet>
      <FilterContent>
        <SearchContainer>
          <SearchInput
            placeholder='搜索設計師名稱、特色或標籤'
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onPressEnter={handleSearch}
            allowClear
            onClear={clearSearch}
          />
          <SearchButton
            disabled={isSearching}
            loading={isSearching}
            onClick={handleSearch}
          >
            搜索
          </SearchButton>
        </SearchContainer>

        <DesktopRegionFilter>
          <Divider style={{ margin: '20px 0 0 0' }}>
            <RegionTitle>縣市篩選</RegionTitle>
          </Divider>
          <RegionAll
            $active={selectedCity === 'all'}
            onClick={() => handleCitySelect('all')}
          >
            全部地區
          </RegionAll>
          <RegionGrid>
            {cityData.counties.map((city) => (
              <RegionItem
                key={city}
                $active={selectedCity === city}
                onClick={() => handleCitySelect(city)}
              >
                {city}
              </RegionItem>
            ))}
          </RegionGrid>
        </DesktopRegionFilter>

        {selectedCity !== 'all-city' && districts.length > 0 && (
          <DistrictFilter>
            <RegionTitle>區域篩選</RegionTitle>
            <RegionGrid>
              {districts.map((district) => (
                <RegionItem
                  key={district}
                  $active={selectedDistrict === district}
                  onClick={() => handleDistrictSelect(district)}
                >
                  {district}
                </RegionItem>
              ))}
            </RegionGrid>
          </DistrictFilter>
        )}
      </FilterContent>

      <MobileRegionButton onClick={() => setShowPopup(true)}>
        <RegionButtonContent>
          <RegionIcon />
          <RegionText $hasSelection={selectedCity !== 'all'}>
            {selectedCity === 'all' ? '按地區搜尋' : selectedCity}
          </RegionText>
        </RegionButtonContent>
        <DownIcon />
      </MobileRegionButton>

      <Popup
        visible={showPopup}
        onMaskClick={() => setShowPopup(false)}
        position='bottom'
        bodyStyle={{ height: '90vh', display: 'flex', flexDirection: 'column' }}
      >
        <PopupContent>
          <PopupTitle>選擇地區</PopupTitle>
          <RegionAll
            $active={selectedCity === 'all'}
            onClick={() => handleCitySelect('all')}
          >
            全部地區
          </RegionAll>
          <PopupGrid>
            {cityData.counties.map((city) => (
              <RegionItem
                key={city}
                $active={selectedCity === city}
                onClick={() => handleCitySelect(city)}
              >
                {city}
              </RegionItem>
            ))}
          </PopupGrid>

          {selectedCity && districts.length > 0 && (
            <DistrictsGrid>
              {districts.map((district) => (
                <DistrictItem
                  key={district}
                  $active={selectedDistrict === district}
                  onClick={() => handleDistrictSelect(district)}
                >
                  {district}
                </DistrictItem>
              ))}
            </DistrictsGrid>
          )}
        </PopupContent>
        <BackButtonContainer>
          <BackButton onClick={() => setShowPopup(false)}>返回</BackButton>
        </BackButtonContainer>
      </Popup>

      <MainContent>
        <CategoriesContainer>
          {categories.map((category, index) => (
            <CategoryItem
              key={index}
              $active={selectedCategory === category.type}
              onClick={() => handleCategorySelect(category.type)}
            >
              <CategoryIcon src={`${category.icon}`} alt={category.name} />
              <CategoryName>{category.name}</CategoryName>
            </CategoryItem>
          ))}
        </CategoriesContainer>

        <TitleH1>
          <LocationText>
            {selectedCity === 'all'
              ? '台灣'
              : selectedDistrict
              ? `${selectedCity}${selectedDistrict}`
              : selectedCity}
          </LocationText>
          <ServiceText>
            {selectedCategory === 'all'
              ? '所有美容、美甲、美髮'
              : translateCategoryToChinese(selectedCategory)}
            師
          </ServiceText>
        </TitleH1>

        {keyword && (
          <SearchInfo>
            搜尋「<strong>{keyword}</strong>」的結果
          </SearchInfo>
        )}

        <PhotoWall>
          {validStylists.length > 0 ? (
            validStylists.map((designer) => (
              <PhotoItem
                key={designer._id}
                onClick={() => navigate(`/${designer.username}`)}
              >
                <PhotoImage
                  src={
                    designer.avatar
                      ? `${PHOTO_URL}${designer.avatar}`
                      : `${PHOTO_URL}/public/photos/stylist_default.png`
                  }
                  alt={designer.name}
                />
                <PhotoOverlay>
                  <DesignerInfo>
                    <StylistName>
                      {designer.name.length > 9
                        ? designer.name.slice(0, 9) + '...'
                        : designer.name}
                    </StylistName>
                    <BusinessInfo>
                      {designer.employeeType} ·{' '}
                      {designer.area?.join('/') || '暫無地區'}
                    </BusinessInfo>
                    <TagsContainer>
                      {window.innerWidth <= 1155 ? (
                        designer.tags?.slice(-1).map((tag, idx) => (
                          <SkillTag key={`tag-${idx}`} color='#87d068'>
                            {tag}
                          </SkillTag>
                        ))
                      ) : (
                        <>
                          {designer.skillsType.slice(0, 2).map((skill, idx) => (
                            <SkillTag key={idx}>
                              {getServiceTypeName(skill)}
                            </SkillTag>
                          ))}
                          {designer.yearsOfExperience > 0 && (
                            <ExperienceTag>
                              {designer.yearsOfExperience}年經驗
                            </ExperienceTag>
                          )}
                          {designer.tags?.slice(0, 1).map((tag, idx) => (
                            <SkillTag key={`tag-${idx}`} color='#87d068'>
                              {tag}
                            </SkillTag>
                          ))}
                        </>
                      )}
                    </TagsContainer>
                  </DesignerInfo>
                </PhotoOverlay>
              </PhotoItem>
            ))
          ) : (
            <EmptyContainer>
              <Empty
                description={
                  <div>
                    <p>
                      沒有找到符合
                      {selectedCity !== 'all' &&
                        selectedCity &&
                        (selectedDistrict
                          ? `${selectedCity}${selectedDistrict}`
                          : selectedCity)}
                      條件的設計師
                    </p>
                    <p style={{ fontSize: '12px', color: '#999' }}>
                      {selectedCategory !== 'all' && '試試切換其他分類'}
                      {selectedCity !== 'all' && '或更換其他地區'}
                      {keyword && '或修改搜索關鍵詞'}
                    </p>
                  </div>
                }
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </EmptyContainer>
          )}
        </PhotoWall>

        {validStylists.length > 0 && (
          <PaginationContainer>
            <Pagination
              current={currentPage}
              total={totalResults}
              pageSize={30}
              onChange={handlePageChange}
              showSizeChanger={false}
              showTotal={(total, range) => `共 ${total} 位設計師`}
            />
          </PaginationContainer>
        )}
      </MainContent>
    </Container>
  )
}

export default StylistsPage
