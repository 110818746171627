import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import {
  EnvironmentOutlined,
  ClockCircleOutlined,
  SearchOutlined,
  CalendarOutlined,
  EnvironmentFilled,
  ClockCircleFilled
} from '@ant-design/icons'
import { PHOTO_URL, API_BASE_URL } from '../utils/apiutils'
import {
  Pagination,
  DatePicker,
  TimePicker,
  Select,
  Button,
  Empty,
  Spin
} from 'antd'
import { colors } from '../utils/utils'
import moment from 'moment'
import {
  CascadePicker,
  Button as MobileButton,
  DatePicker as MobileDatePicker,
  Picker
} from 'antd-mobile'
import cityData from '../utils/cityData'
import { Helmet } from 'react-helmet-async'
import { categoriesList } from '../utils/utils'
import { categories } from '../utils/utils'

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px;
  background-color: #f8f9fa;
  animation: ${fadeIn} 0.5s ease-in;

  @media (max-width: 820px) {
    padding: 20px 10px;
  }
`

const Header = styled.div`
  text-align: center;
  margin-bottom: 10px;
`

const Title = styled.h1`
  color: ${colors.primary};
  font-size: 30px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  @media (max-width: 820px) {
    font-size: 24px;
  }
`

const SearchForm = styled.form`
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 820px) {
    padding: 20px;
  }
`

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 820px) {
    display: none;
  }
`

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.label`
  margin-bottom: 8px;
  font-weight: 600;
  color: ${colors.darkGrey};

  @media (max-width: 820px) {
    display: none;
  }
`

const StyledSelect = styled(Select)`
  width: 100%;
  @media (max-width: 820px) {
    display: none;
  }
`

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  @media (max-width: 820px) {
    display: none;
  }
`

const StyledTimePicker = styled(TimePicker.RangePicker)`
  width: 100%;
  @media (max-width: 820px) {
    display: none;
  }
`

const SearchButton = styled(Button)`
  width: 100%;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  background-color: ${colors.primary};
  border-color: ${colors.primary};
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    background-color: ${colors.primaryDark};
    border-color: ${colors.primaryDark};
    transform: translateY(-2px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 820px) {
    margin-top: 10px;
    height: 45px;
    font-size: 16px;
  }
`

const MobileSearchForm = styled.div`
  display: none;

  @media (max-width: 820px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`

const ResultSummary = styled.div`
  text-align: center;
  margin-bottom: 30px;
  font-size: 18px;
  color: ${colors.darkGrey};
  font-weight: 500;
`

const ResultGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
`

const ResultCard = styled.div`
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
`

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
`

const CardContent = styled.div`
  padding: 20px;
`

const CardTitle = styled.h3`
  margin: 0 0 15px 0;
  color: ${colors.primary};
  font-size: 22px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${colors.primaryDark};
  }
`

const CardInfo = styled.p`
  margin: 10px 0;
  color: ${colors.darkGrey};
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
`

const TimeSlotContainer = styled.div`
  margin-top: 15px;
`

const TimeSlotGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`

const TimeSlot = styled.div`
  background-color: ${colors.lightGrey};
  color: ${colors.darkGrey};
  padding: 6px 10px;
  border-radius: 20px;
  font-size: 12px;
  white-space: nowrap;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.primary};
    color: white;
  }
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`

const NoResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`

const NoResultText = styled.p`
  font-size: 18px;
  color: ${colors.darkGrey};
  margin-top: 20px;
  text-align: center;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`

const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin: 20px 0;
  padding: 20px 0;
  border-top: 1px solid #eee;
  grid-column: 1 / -1;

  @media (max-width: 820px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 10px 0;
    padding: 10px 0;
  }
`

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.$active ? '#D5728A' : '#eee')};
  }

  @media (max-width: 820px) {
    justify-content: center;
    padding: 5px;
    margin: 0;
  }
`

const CategoryIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;

  @media (max-width: 820px) {
    width: 20px;
    height: 20px;
  }
`

const CategoryName = styled.div`
  font-size: 16px;

  @media (max-width: 820px) {
    font-size: 14px;
  }
`

const AdvancedSearchResultPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchResults, setSearchResults] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [searchParams, setSearchParams] = useState({
    date: '',
    timeStart: '',
    timeEnd: '',
    city: '',
    area: '',
    page: 1,
    limit: 30,
    sort: 'default',
    serviceTypes: []
  })
  const [areaVisible, setAreaVisible] = useState(false)
  const [dateVisible, setDateVisible] = useState(false)
  const [timeVisible, setTimeVisible] = useState(false)
  const [serviceTypeVisible, setServiceTypeVisible] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const newSearchParams = {
      date: params.get('date') || '',
      timeStart: params.get('timeStart') || '',
      timeEnd: params.get('timeEnd') || '',
      city: params.get('city') || '',
      area: params.get('area') || '',
      page: parseInt(params.get('page') || '1'),
      limit: parseInt(params.get('limit') || '30'),
      sort: params.get('sort') || 'default',
      serviceTypes: params.getAll('serviceType') || []
    }
    setSearchParams(newSearchParams)
    setCurrentPage(newSearchParams.page)
    fetchResults(newSearchParams)
  }, [location])

  const fetchResults = async (params) => {
    setIsLoading(true)
    try {
      const queryParams = new URLSearchParams()
      Object.entries(params).forEach(([key, value]) => {
        if (key === 'serviceTypes' && Array.isArray(value)) {
          value.forEach((type) => {
            queryParams.append('serviceType', type)
          })
        } else if (value) {
          queryParams.append(key, value)
        }
      })

      const response = await axios.get(`${API_BASE_URL}/api/search/advanced`, {
        params: queryParams
      })
      setSearchResults(response.data.businesses)
      setTotalResults(response.data.totalResults)
    } catch (err) {
      setError('獲取搜索結果時出錯: ' + err.message)
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const areaOptionsArray = cityData.districts.map((district) => ({
    label: district.name,
    value: district.name,
    children: district.areas.map((area) => ({
      label: area,
      value: area
    }))
  }))

  const handleAreaConfirm = (val) => {
    handleSearchChange('city', val[0])
    handleSearchChange('area', val[1])
    setAreaVisible(false)
  }

  const handleCityChange = (value) => {
    handleSearchChange('city', value)
    handleSearchChange('area', '') // 重置区域选择
  }

  const handleAreaChange = (value) => {
    handleSearchChange('area', value)
  }

  // 生成时间选项
  const generateTimeOptions = () => {
    const options = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}`
        options.push({ label: time, value: time })
      }
    }
    return options
  }

  const timeOptions = generateTimeOptions()

  const handleDateConfirm = (date) => {
    handleSearchChange('date', date.toISOString().split('T')[0])
    setDateVisible(false)
  }

  const handleTimeConfirm = (val) => {
    handleSearchChange('timeStart', val[0])
    handleSearchChange('timeEnd', val[1])
    setTimeVisible(false)
  }

  const handleCardClickBusiness = (username) => {
    navigate(`/store/${username}`)
  }

  const handlePageChange = (page) => {
    const newParams = { ...searchParams, page }
    setCurrentPage(page)
    updateSearch(newParams)
  }

  const handleSearchChange = (name, value) => {
    setSearchParams((prev) => ({ ...prev, [name]: value }))
  }

  const handleSearch = (e) => {
    e.preventDefault()
    updateSearch({ ...searchParams, page: 1 })
  }

  const updateSearch = (params) => {
    const searchParams = new URLSearchParams()

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'serviceTypes' && Array.isArray(value)) {
        value.forEach((type) => {
          searchParams.append('serviceType', type)
        })
      } else if (value) {
        searchParams.append(key, value)
      }
    })

    navigate({
      pathname: location.pathname,
      search: searchParams.toString()
    })
  }

  const renderBusinessCard = (business) => (
    <ResultCard key={business._id}>
      <CardImage
        src={
          business.photos && business.photos.length > 0
            ? `${PHOTO_URL}${business.photos[0].url}`
            : '/assets/business_photo.png'
        }
        alt={business.name}
        onClick={() => handleCardClickBusiness(business.username)}
      />
      <CardContent>
        <CardTitle onClick={() => handleCardClickBusiness(business.username)}>
          {business.name}
        </CardTitle>
        <CardInfo>
          <EnvironmentOutlined /> {business.address}
        </CardInfo>
        <CardInfo>{business.description}</CardInfo>
        <CardInfo>{business.reviews?.length || 0} 則評論</CardInfo>
        {business.availableTimeSlots &&
          business.availableTimeSlots.length > 0 && (
            <TimeSlotContainer>
              <CardInfo>
                <ClockCircleOutlined /> 可預約時間
              </CardInfo>
              <TimeSlotGrid>
                {business.availableTimeSlots
                  .slice(0, 5)
                  .map((timeSlot, index) => (
                    <TimeSlot key={index}>{timeSlot.start}</TimeSlot>
                  ))}
                {business.availableTimeSlots.length > 5 && (
                  <TimeSlot>+{business.availableTimeSlots.length - 5}</TimeSlot>
                )}
              </TimeSlotGrid>
            </TimeSlotContainer>
          )}
      </CardContent>
    </ResultCard>
  )

  const renderSearchResults = () => {
    if (isLoading) {
      return (
        <LoadingContainer>
          <Spin size='large' />
        </LoadingContainer>
      )
    }

    if (error) {
      return (
        <NoResultContainer>
          <NoResultText>{error}</NoResultText>
        </NoResultContainer>
      )
    }

    if (searchResults.length === 0) {
      return (
        <NoResultContainer>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <NoResultText>
                抱歉，沒有找到符合條件的結果。請嘗試調整搜索條件。
              </NoResultText>
            }
          />
        </NoResultContainer>
      )
    }

    return (
      <>
        <ResultSummary>共找到 {totalResults} 條符合條件的結果</ResultSummary>
        <ResultGrid>{searchResults.map(renderBusinessCard)}</ResultGrid>
        <PaginationWrapper>
          <Pagination
            current={currentPage}
            total={totalResults}
            pageSize={searchParams.limit}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </PaginationWrapper>
      </>
    )
  }

  return (
    <Container>
      <Helmet>
        <title>{`${searchParams.city === 'all' ? '全台' : searchParams.city}${
          searchParams.area === null ? '' : searchParams.area
        }美容美甲美髮睫毛紋繡霧唇霧眉店家 & 線上預約推薦 - 美感｜BeauteFeel`}</title>
        <meta
          name='description'
          content={`美感幫你把所有美容、美甲、美髮、睫毛、紋繡、霧唇、霧眉的最新話題都整理好了！所有最新話題與店家設計師專欄都在這裡，看看他們怎麼說！`}
        />
      </Helmet>
      <Header>
        <Title>搜索結果</Title>
      </Header>
      <SearchForm onSubmit={handleSearch}>
        <FormGrid>
          <FormItem>
            <Label>
              <CalendarOutlined /> 日期
            </Label>
            <StyledDatePicker
              value={searchParams.date ? moment(searchParams.date) : null}
              onChange={(date, dateString) =>
                handleSearchChange('date', dateString)
              }
            />
          </FormItem>
          <FormItem>
            <Label>
              <EnvironmentFilled /> 地區
            </Label>
            <StyledSelect
              placeholder='選擇縣市'
              value={searchParams.city}
              onChange={handleCityChange}
            >
              {cityData.counties.map((city) => (
                <Select.Option key={city} value={city}>
                  {city}
                </Select.Option>
              ))}
            </StyledSelect>
          </FormItem>
          <FormItem>
            <Label>&nbsp;</Label>
            <StyledSelect
              placeholder='選擇區域'
              value={searchParams.area}
              onChange={handleAreaChange}
              disabled={!searchParams.city}
            >
              {searchParams.city &&
                cityData.districts
                  .find((d) => d.name === searchParams.city)
                  ?.areas.map((area) => (
                    <Select.Option key={area} value={area}>
                      {area}
                    </Select.Option>
                  ))}
            </StyledSelect>
          </FormItem>
          <FormItem>
            <Label>
              <ClockCircleFilled /> 時間範圍
            </Label>
            <StyledTimePicker
              value={[
                searchParams.timeStart
                  ? moment(searchParams.timeStart, 'HH:mm')
                  : null,
                searchParams.timeEnd
                  ? moment(searchParams.timeEnd, 'HH:mm')
                  : null
              ]}
              onChange={(times, timeStrings) => {
                handleSearchChange('timeStart', timeStrings[0])
                handleSearchChange('timeEnd', timeStrings[1])
              }}
              format='HH:mm'
            />
          </FormItem>
        </FormGrid>

        <CategoriesContainer>
          {categories.map((category) => (
            <CategoryItem
              key={category.type}
              $active={
                category.type === 'all'
                  ? searchParams.serviceTypes.length === categories.length - 1 // 减1是因为不包括"全部"选项
                  : searchParams.serviceTypes.includes(category.type)
              }
              onClick={() => {
                let newServiceTypes
                if (category.type === 'all') {
                  // 如果点击"全部"
                  if (
                    searchParams.serviceTypes.length ===
                    categories.length - 1
                  ) {
                    // 如果已经全选,则清空
                    newServiceTypes = []
                  } else {
                    // 否则选择所有(除了"all"类型)
                    newServiceTypes = categories
                      .filter((cat) => cat.type !== 'all')
                      .map((cat) => cat.type)
                  }
                } else {
                  // 点击具体类型
                  if (searchParams.serviceTypes.includes(category.type)) {
                    // 如果已选中,则取消选中
                    newServiceTypes = searchParams.serviceTypes.filter(
                      (type) => type !== category.type
                    )
                  } else {
                    // 如果未选中,则添加选中
                    newServiceTypes = [
                      ...searchParams.serviceTypes,
                      category.type
                    ]
                  }
                }

                handleSearchChange('serviceTypes', newServiceTypes)

                // 如果需要立即触发搜索,可以调用:
                updateSearch({
                  ...searchParams,
                  serviceTypes: newServiceTypes,
                  page: 1
                })
              }}
            >
              <CategoryIcon src={category.icon} alt={category.name} />
              <CategoryName>{category.name}</CategoryName>
            </CategoryItem>
          ))}
        </CategoriesContainer>

        <MobileSearchForm>
          <MobileButton onClick={() => setDateVisible(true)}>
            {searchParams.date
              ? moment(searchParams.date).format('YYYY-MM-DD')
              : '選擇日期'}
          </MobileButton>
          <MobileButton onClick={() => setAreaVisible(true)}>
            {searchParams.city && searchParams.area
              ? `${searchParams.city} ${searchParams.area}`
              : '選擇地區'}
          </MobileButton>
          <MobileButton onClick={() => setTimeVisible(true)}>
            {searchParams.timeStart && searchParams.timeEnd
              ? `${searchParams.timeStart} - ${searchParams.timeEnd}`
              : '選擇時間'}
          </MobileButton>
        </MobileSearchForm>

        <SearchButton
          type='primary'
          htmlType='submit'
          icon={<SearchOutlined />}
        >
          重新搜索
        </SearchButton>
      </SearchForm>

      {/* 移動版選擇器 */}
      <CascadePicker
        title='選擇地區'
        visible={areaVisible}
        options={areaOptionsArray}
        onClose={() => setAreaVisible(false)}
        onConfirm={handleAreaConfirm}
      />
      <MobileDatePicker
        visible={dateVisible}
        onClose={() => setDateVisible(false)}
        onConfirm={handleDateConfirm}
      />
      <Picker
        visible={timeVisible}
        onClose={() => setTimeVisible(false)}
        onConfirm={handleTimeConfirm}
        columns={[timeOptions, timeOptions]}
        title='選擇時間範圍'
      />
      <Picker
        visible={serviceTypeVisible}
        onClose={() => setServiceTypeVisible(false)}
        onConfirm={(values) => {
          handleSearchChange('serviceTypes', values)
          setServiceTypeVisible(false)
        }}
        value={searchParams.serviceTypes}
        columns={[
          categoriesList.map((category) => ({
            label: category.name,
            value: category.type
          }))
        ]}
        title='選擇服務類型'
      />

      {renderSearchResults()}
    </Container>
  )
}

export default AdvancedSearchResultPage
